// src/utils/constants/svgPathWatermark.js
export const svgPathWatermark = [
  'M125.32668,12.42373a41.75335,41.75335,0,0,1,11.98741,29.20659,41.31841,41.31841,0,0,1-23.97541,37.48907,36.39667,36.39667,0,0,1-11.76954,3.26978h-1.3078V82.607H27.24459v70.18271H0V.21846H96.1201a40.849,40.849,0,0,1,28.98871,11.98741ZM102.00487,54.05405a13.84272,13.84272,0,0,0,8.28249-12.6416,14.37946,14.37946,0,0,0-4.14125-10.026A13.73125,13.73125,0,0,0,96.338,27.46306H27.24459V55.57971H96.338a12.7138,12.7138,0,0,0,5.66691-1.30779Z',
  'M282.041,125.98087l13.94939,27.2446H265.476l-13.9494-27.2446-14.603-28.553-.65419-1.30779a27.00615,27.00615,0,0,0-23.10335-13.07733H185.92089v70.1827H158.6763V.65419h96.338a40.92868,40.92868,0,0,1,40.97612,40.97613A41.31841,41.31841,0,0,1,272.015,79.11939a36.40419,36.40419,0,0,1-11.77013,3.26978ZM255.01426,55.79758a12.72127,12.72127,0,0,0,5.6669-1.3072,13.84515,13.84515,0,0,0,8.28249-12.6422,14.37944,14.37944,0,0,0-4.14124-10.026,13.73241,13.73241,0,0,0-9.80815-3.92338H185.92089V56.016Z',
  'M465.34569,76.5038A76.28564,76.28564,0,1,1,389.06036.21846,76.36946,76.36946,0,0,1,465.34569,76.5038Zm-27.24459,0a49.04075,49.04075,0,1,0-49.04074,49.04075A49.11484,49.11484,0,0,0,438.1011,76.5038Z',
  'M634.7028,0V27.24519H523.32611a14.04715,14.04715,0,0,0-13.9494,13.94939V55.36184H607.4582V82.607h-98.3v69.96484H482.13152V41.41245A41.1149,41.1149,0,0,1,523.10765.21846Z',
  'M656.28108,152.78974V.21846h27.2446V152.78974Z',
  'M857.674.43633V27.68092H795.11963V153.0076H767.875V27.68092H705.32063V.43633Z',
  'M906.93265,41.41245V55.57971h98.08209V82.8249H906.93265v28.77025a14.04715,14.04715,0,0,0,13.9494,13.9494h111.37728v27.24519H920.88205a41.115,41.115,0,0,1-40.97613-41.19459V41.41245A41.1149,41.1149,0,0,1,920.88205.21846h111.37728v27.2446H920.88205A14.04753,14.04753,0,0,0,906.93265,41.41245Z',
  'M1054.05548,152.78974V.21846h27.2446V152.78974Z',
  'M1250,152.78974h-30.51437l-13.9494-27.24519-33.34782-65.38727-33.34783,65.38727-13.94939,27.24519h-30.51378l13.94939-27.24519L1172.18841.21846l63.8622,125.32609Z'
]
