// src/utils/chartComputed.js
import { computed } from 'vue'
import { dateTimeLabelFormats, credits } from './constants/chartConstants'
import { selectableCountriesBySource } from './constants/selectableCountriesBySource'
import { mdiMoleculeCo2, mdiFire, mdiBarrel, mdiEarth, mdiPowerPlug, mdiGasCylinder, mdiShipWheel, mdiFinance } from '@mdi/js'
import i18n from '@/i18n'

export const useCountrySelect = (displayOptions) => {
  return computed(() => {
    return displayOptions.value.countrySelect !== undefined
      ? displayOptions.value.countrySelect
      : null
  })
}

export const computedTitle = (displayOptions, fetchOptions, selectedCountry) => {
  const isDynamicCountry = ('country_code' in fetchOptions.value && 'countrySelect' in displayOptions.value)
  const isDynamicICEIndex = ('ice_index' in fetchOptions.value && 'countrySelect' in displayOptions.value)

  return computed(() => {
    let returnString = i18n.global.t(displayOptions.value.title)

    if (isDynamicCountry || isDynamicICEIndex) {
      returnString += ` (${selectedCountry.value.label})`
    }

    if ('displayDate' in displayOptions.value) {
      // returns: Intraday spread - 2023-10-27 00:00:00.000
      returnString += ' - ' + fetchOptions.value.start_date.split(/[ T]/)[0]
    }
    return returnString
  })
}

// Generate dropdown for countrySelect
export const computedCountrySelectOptions = (fetchOptions, displayOptions) => {
  return computed(() => {
    const isDynamicCountry = ('country_code' in fetchOptions.value && 'countrySelect' in displayOptions.value)
    const isDynamicICEIndex = ('ice_index' in fetchOptions.value && 'countrySelect' in displayOptions.value)

    // Check if we have a countrySelect in displayOptions
    if (displayOptions.value.countrySelect === undefined) {
      return null
    }

    // Get the countryOptions for the chart
    const countryOptions = selectableCountriesBySource[displayOptions.value.countrySelect]
    let defaultCountry = isDynamicCountry ? fetchOptions.value.country_code : 'NL'

    // If ice_index is dynamic, set the defaultCountry to the selected ice_index
    if (isDynamicICEIndex) {
      const item = countryOptions.find(obj => obj.value === fetchOptions.value.ice_index)
      defaultCountry = item.label
    }

    // set defaultCountry to the first position and NL to second
    countryOptions.sort((a, b) => {
      if (a.label === defaultCountry) return -1
      if (b.label === defaultCountry) return 1
      if (a.label === 'NL') return -1
      if (b.label === 'NL') return 1
      return 0
    })

    // Add id to the countryOptions
    countryOptions.forEach((item, index) => {
      item.id = index
    })

    return countryOptions
  })
}

export const computedCardIcon = (displayOptions) => {
  return computed(() => {
    if (displayOptions.value.icon === 'mdiMoleculeCo2') {
      return mdiMoleculeCo2
    } else if (displayOptions.value.icon === 'mdiFire') {
      return mdiFire
    } else if (displayOptions.value.icon === 'mdiBarrel') {
      return mdiBarrel
    } else if (displayOptions.value.icon === 'mdiEarth') {
      return mdiEarth
    } else if (displayOptions.value.icon === 'mdiPowerPlug') {
      return mdiPowerPlug
    } else if (displayOptions.value.icon === 'mdiGasCylinder') {
      return mdiGasCylinder
    } else if (displayOptions.value.icon === 'mdiShipWheel') {
      return mdiShipWheel
    }
    return mdiFinance
  })
}

export const computedSelectableSeriesNames = (series) => {
  return computed(() => {
    let id = -1

    if (series.value === null || series.value.length === 0) {
      return []
    }

    return series.value
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => {
        id++
        return {
          id: id,
          visible: item.visible,
          name: item.name
        }
      })
  })
}

export const computedTooltip = (displayOptions) => {
  return computed(() => {
    const tooltipDefault = {
      headerFormat: '<b>{series.name}</b><br>{point.key}: ',
      pointFormat: '{point.y:.2f}'
    }

    if ('tooltip' in displayOptions.value) {
      return displayOptions.value.tooltip
    }

    return tooltipDefault
  })
}

export const computedTimeOptions = (displayOptions) => {
  return computed(() => {
    if ('time' in displayOptions.value) {
      return displayOptions.value.time
    }
    return {
      timezone: 'Europe/Amsterdam',
      useUTC: false
    }
  })
}

export const computedPlotOptions = (displayOptions) => {
  return computed(() => {
    const plotOptionsDefault = {
      series: {
        connectNulls: displayOptions.value.connectNulls || false,
        lineWidth: 10,
        marker: {
          enabled: false
        }
      }
    }

    if ('plotOptions' in displayOptions.value) {
      return displayOptions.value.plotOptions
    }

    return plotOptionsDefault
  })
}

export const computedXAxis = (displayOptions, categories, xAxisLabel) => {
  return computed(() => {
    const xAxisDefault = {
      type: displayOptions.value.xAxisType || 'datetime',
      dateTimeLabelFormats: displayOptions.value.dateTimeLabelFormats || dateTimeLabelFormats,
      categories: displayOptions.value.xAxisType === 'category' ? categories.value : undefined,
      title: {
        text: xAxisLabel
      },
      tickmarkPlacement: 'between',
      tickAmount: 12,
      labels: {
        style: {
          textOverflow: 'none'
        }
      }
    }

    if ('xAxis' in displayOptions.value) {
      return displayOptions.value.xAxis
    }

    return xAxisDefault
  })
}

export const computedYAxis = (displayOptions) => {
  const yAxisMin = 'yAxisMin' in displayOptions.value ? displayOptions.value.yAxisMin : null
  const yAxisMax = 'yAxisMax' in displayOptions.value ? displayOptions.value.yAxisMax : null
  const yAxisTickPositions = 'yAxisTickPositions' in displayOptions.value ? displayOptions.value.yAxisTickPositions : null
  const yAxisLabel = 'yAxisLabel' in displayOptions.value ? i18n.global.t(displayOptions.value.yAxisLabel) : i18n.global.t('chart_labels.value')

  return computed(() => {
    const yAxisDefault = {
      min: yAxisMin,
      max: yAxisMax,
      tickPositions: yAxisTickPositions,
      startOnTick: true,
      title: {
        text: yAxisLabel
      },
      opposite: true,
      tickmarkPlacement: 'between',
      tickAmount: 6
    }

    if ('yAxis' in displayOptions.value) {
      const translatedArray = []

      if (Array.isArray(displayOptions.value.yAxis)) {
        displayOptions.value.yAxis.forEach(function (yAxisElement) {
          if ('title' in yAxisElement) {
            yAxisElement.title.text = i18n.global.t(yAxisElement.title.text)
          }
          translatedArray.push(yAxisElement)
        })
      }

      return translatedArray
    }

    return yAxisDefault
  })
}
