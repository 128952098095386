import { ref, watch, computed, toRaw } from 'vue'
import {
  computedTimeOptions,
  computedTitle,
  computedTooltip,
  computedPlotOptions,
  computedXAxis,
  computedYAxis,
  computedCountrySelectOptions
} from '@/utils/chartComputed'
import { credits } from '@/utils/constants/chartConstants'
import i18n from '@/i18n'
import { syncChart, syncChartDimensions, addWatermark } from '@/utils/chart'
import { useStore } from 'vuex'

export const events = (uuid, displayOptions, syncChart) => ({
  addSeries: function () {
    const chart = this
    chart.hideLoading(i18n.global.t('loading'))
  },
  load: function () {
    const chart = this
    // chart.showLoading(i18n.global.t('loading'))
    chart.showLoading('<img src="/images/icons/spinner-round.gif"/>')
    // addWatermark(chart)
  },
  setExtremes: function () {
    this.redraw()
  },
  redraw: function () {
    const chart = this
    const extremesY = chart.yAxis[0].getExtremes()
    const extremesX = chart.xAxis[0].getExtremes()

    syncChart({
      uuid,
      displayOptions,
      syncDimensions: {
        height: chart.chartHeight,
        width: chart.chartWidth,
        xMin: extremesX.min,
        xMax: extremesX.max,
        yMin: extremesY.min,
        yMax: extremesY.max
      }
    })
    addWatermark(chart)
  }
})

export function getChartOptions (uuid, displayOptions, fetchOptions, series, categories, yAxisCategories) {
  // Computed chart options
  const chartOptions = computed(() => {
    const timeOptions = computedTimeOptions(displayOptions)
    const plotOptions = computedPlotOptions(displayOptions)
    const countrySelectOptions = computedCountrySelectOptions(fetchOptions, displayOptions)
    const selectedCountry = ref(countrySelectOptions.value && countrySelectOptions.value.length > 0 ? countrySelectOptions.value[0] : null)
    const tooltip = computedTooltip(displayOptions)

    const xAxisLabel = 'xAxisLabel' in displayOptions.value ? i18n.global.t(displayOptions.value.xAxisLabel) : i18n.global.t('chart_labels.date')
    const xAxis = computedXAxis(displayOptions, categories, xAxisLabel)
    const yAxis = computedYAxis(displayOptions, yAxisCategories)
    const title = computedTitle(displayOptions, fetchOptions, selectedCountry)

    return {
      chart: {
        styledMode: true,
        backgroundColor: 'transparent',
        type: displayOptions.value.chartType || 'line',
        zoomType: 'x',
        events: events(uuid, displayOptions, syncChart),
        marginBottom: 50,
        plotBackgroundImage: 'https://profiteia.io/images/profiteia_logo_text.svg'
      },
      legend: {
        enabled: false
      },
      time: timeOptions.value,
      credits,
      plotOptions: plotOptions.value,
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
        months: i18n.global.t('months'),
        shortMonths: i18n.global.t('shortMonths'),
        weekdays: i18n.global.t('weekdays')
      },
      backgroundColor: 'transparent',
      uuid: uuid.value,
      exporting: {
        enabled: false,
        enableImages: true,
        chartOptions: {
          title: {
            text: title.value
          },
          chart: {
            backgroundColor: '#FFFFFF',
            events: {
              load: function () {
                this.container.classList.add('export')
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        color: '#000000'
      },
      tooltip: tooltip.value,
      colors: ['#808080', '#8FD694', '#8bd600', '#7DBA84', '#77AD78', '#6F8F72', '#504B43'],
      colorAxis: displayOptions.value.colorAxis || undefined,
      title: null, // do not use titles
      series: series.value,
      xAxis: xAxis.value,
      yAxis: yAxis.value
    }
  })

  return chartOptions
}
