import { createRouter, createWebHashHistory } from 'vue-router'

import About from '../views/About'
import Admin from '../views/Admin'
import Contact from '../views/Contact'
import Dashboard from '../views/Dashboard'
import DashboardPor from '../views/DashboardPor'
import DashboardCalculate from '../views/DashboardCalculate'

import nsTypeSchema from '@/dashboards/ns.json'
import testTypeSchema from '@/dashboards/test.json'
import outlookTypeSchema from '@/dashboards/outlook.json'
import batteryTypeSchema from '@/dashboards/batteries.json'
import dashboardTypeSchema from '@/dashboards/dashboard.json'
import imbalanceTypeSchema from '@/dashboards/imbalance.json'
import batterySimulationTypeSchema from '@/dashboards/batterysimulation.json'
import imbalancePredictionTypeSchema from '@/dashboards/imbalance-prediction.json'

import i18n from '@/i18n'

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: i18n.global.t('page_titles.dashboard'),
      typeSchema: dashboardTypeSchema
    },
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.dashboard'),
      typeSchema: nsTypeSchema
    },
    path: '/ns',
    name: 'dashboardns',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.dashboard_imbalance'),
      typeSchema: imbalanceTypeSchema
    },
    path: '/reports/imbalance',
    name: 'imbalance',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.dashboard_por')
    },
    path: '/port-of-rotterdam',
    name: 'dashboard-port-of-rotterdam',
    component: DashboardPor
  },
  {
    meta: {
      title: i18n.global.t('page_titles.dashboard_battery_calculation'),
      requiresAuth: true
    },
    path: '/reports/battery-calculation',
    name: 'calculate',
    component: DashboardCalculate
  },
  {
    meta: {
      title: i18n.global.t('page_titles.energy_outlook'),
      typeSchema: outlookTypeSchema,
      requiresAuth: true
    },
    path: '/energierapportage',
    name: 'dashboard-energierapportage',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.energy_outlook'),
      typeSchema: testTypeSchema,
      requiresAuth: false
    },
    path: '/test',
    name: 'test',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.energy_outlook'),
      typeSchema: outlookTypeSchema,
      requiresAuth: true
    },
    path: '/reports/outlook',
    name: 'dashboard-energierapportage',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.imbalance_prediction'),
      typeSchema: imbalancePredictionTypeSchema,
      requiresAuth: false
    },
    path: '/reports/imbalance-prediction',
    name: 'dashboard-imbalance-prediction',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.batteries'),
      typeSchema: batteryTypeSchema,
      requiresAuth: true
    },
    path: '/reports/batteries',
    name: 'dashboard-batteries',
    component: Dashboard
  },
  {
    meta: {
      title: i18n.global.t('page_titles.battery_simulation'),
      typeSchema: batterySimulationTypeSchema,
      requiresAuth: true
    },
    path: '/reports/battery-simulation',
    name: 'dashboard-battery-simulation',
    component: Dashboard
  },
  // {
  //   meta: {
  //     title: 'UI Components'
  //   },
  //   path: '/ui',
  //   name: 'ui',
  //   component: () => import(/* webpackChunkName: "forms" */ '../views/Ui')
  // },
  {
    meta: {
      title: i18n.global.t('page_titles.contact')
    },
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    meta: {
      title: i18n.global.t('page_titles.about')
    },
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "profile" */ '../views/About')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.admin')
    },
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    meta: {
      title: i18n.global.t('page_titles.error'),
      formScreen: true
    },
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "remind" */ '../views/Error')
  },
  { // Login stuff
    meta: {
      title: i18n.global.t('page_titles.profile')
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Account/Profile')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.profile')
    },
    path: '/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Account/Alerts')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.login'),
      formScreen: true
    },
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Account/Login')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.register'),
      formScreen: true
    },
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Account/Register')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.activate_email')
    },
    path: '/account/activate/:activationCode',
    name: 'activate',
    component: () => import(/* webpackChunkName: "activate" */ '../views/Account/CheckActivationCode')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.resend_activation'),
      formScreen: true
    },
    path: '/account/activation/resend',
    name: 'resend-activation',
    component: () => import(/* webpackChunkName: "activate" */ '../views/Account/ResendActivationCode')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.return_subscription')
    },
    path: '/subscription/:subscriptionState/:subscriptionId',
    name: 'subscription',
    component: () => import('../views/Account/Subscription.vue')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.recover_password'),
      formScreen: true
    },
    path: '/account/recover',
    name: 'recover',
    component: () => import(/* webpackChunkName: "remind" */ '../views/Account/SendPasswordRecovery')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.recover_password'),
      formScreen: true
    },
    path: '/account/recover/:recoverCode',
    name: 'recover_password',
    component: () => import(/* webpackChunkName: "remind" */ '../views/Account/RecoverPassword')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.menu_subscriptions')
    },
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing')
  },
  // {
  //   meta: {
  //     title: 'Charts'
  //   },
  //   path: '/charts',
  //   name: 'charts',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tables" */ '../views/Charts')
  // },
  // {
  //   meta: {
  //     title: 'Tables & Lists'
  //   },
  //   path: '/tables-and-lists',
  //   name: 'tables-and-lists',
  //   component: () => import(/* webpackChunkName: "tables" */ '../views/TablesLists')
  // },
  // {
  //   meta: {
  //     title: 'Forms'
  //   },
  //   path: '/forms',
  //   name: 'forms',
  //   component: () => import(/* webpackChunkName: "forms" */ '../views/Forms')
  // },
  // {
  //   meta: {
  //     title: 'UI Components'
  //   },
  //   path: '/ui',
  //   name: 'ui',
  //   component: () => import(/* webpackChunkName: "forms" */ '../views/Ui')
  // },
  {
    meta: {
      title: i18n.global.t('page_titles.page_not_found'),
      formScreen: true
    },
    path: '/page-not-found',
    name: 'missing-page',
    component: () => import(/* webpackChunkName: "forms" */ '../views/PageNotFound')
  },
  {
    meta: {
      title: i18n.global.t('page_titles.page_not_found'),
      formScreen: true
    },
    beforeEnter (to) {
      window.location = '/#/page-not-found'
    },
    path: '/:pathMatch(.*)*',
    name: 'error'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

export default router
