<template>
  <footer class="bg-stone-100 py-6 sticky top-[100vh]">
    <div class="w-full flex justify-start items-start mt-10">
      <!-- Logo and tagline -->
      <div class="w-64 ml-5">
        <div v-if="isMobileScreen" class="pb-5">
          <mobile-footer-menu/>
        </div>
        <router-link to="/">
            <span class="bg-clip-text text-transparent bg-gradient-to-br from-emerald-700 to-emerald-400 font-azonix text-2xl mr-5">
              Profiteia
            </span>
          </router-link>
          <p class="mb-4 text-3xl font-bold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white max-w-64">
            {{ $t('general_tagline') }}
          </p>
          &copy; {{ year }} - profiteia.io
      </div>

      <!-- Navigation links -->
      <div v-if="!isMobileScreen" class="flex ml-10 space-x-2 text-black items-start">
        <main-menu/>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import JbButton from '@/components/JbButton'
import MainMenu from '@/components/navigation/MainMenu'
import MobileFooterMenu from './navigation/MobileFooterMenu.vue'
import NavBarItem from '@/components/NavBarItem'
import NavBarItemLabel from '@/components/NavBarItemLabel'
import NavBarMenu from '@/components/NavBarMenu'
import NavBarMenuDivider from '@/components/NavBarMenuDivider'
import {
  mdiForwardburger,
  mdiBackburger,
  mdiClose,
  mdiDotsVertical,
  mdiMenu,
  mdiHome,
  mdiCrown,
  mdiCheck,
  mdiAccount,
  mdiCogOutline,
  mdiLogin,
  mdiLogout,
  mdiBell,
  mdiEmailNewsletter,
  mdiThemeLightDark,
  mdiEmailOpen,
  mdiInformationOutline,
  mdiEarth,
  mdiFlag
} from '@mdi/js'

const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

const setLocale = (locale) => {
  store.dispatch('display/setLocale', locale)
}
const year = computed(() => new Date().getFullYear())
const currentLocale = computed(() => t('language_short'))
const isMobileScreen = computed(() => store.state.display.isXl === false && store.state.display.isLg === false)

</script>
