<template>
  <section :class="{'py-6':!isFormScreen}" class="px-0 md:px-10">
    <slot/>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'MainSection',
  setup () {
    const store = useStore()

    const isFormScreen = computed(() => store.state.display.isFormScreen)

    return {
      isFormScreen
    }
  }
}
</script>
