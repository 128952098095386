<template>
  <!-- Right Section: Customized Solutions and Chart -->
  <div class="lg:w-1/3 relative lg:ml-auto lg:-mr-16"> <!-- Apply negative margin here -->
    <!-- Red background container for styling -->
    <div v-if="!isMobileScreen" class="bg-white bg-opacity-40 pt-2 pr-0 rounded-xl border-t border-b border-gray-100 shadow-lg absolute -top-12 left-12 w-full h-full z-0"></div>
    <div v-if="!isMobileScreen" class="bg-white bg-opacity-60 pt-2 pr-0 rounded-xl shadow-lg absolute -top-8 left-8 w-full h-full z-0"></div>
    <div v-if="!isMobileScreen" class="bg-white bg-opacity-80 pt-2 pr-0 rounded-xl shadow-lg absolute -top-4 left-4 w-full h-full z-0"></div>

    <!-- Gray content container for the chart and content -->
    <div class="relative bg-white p-4 lg:p-6 rounded-xl lg:shadow-lg z-10">
      <div class="p-2 mb-2 lg:mb-4 lg:p-0">
        <h2 class="text-2xl font-bold text-gray-800">{{ t('herobar_chart_card_example') }}</h2>
        <p class="text-gray-500">{{ title }}</p>
      </div>

      <!-- Chart Container -->
      <div class="bg-white rounded-lg lg:p-4 shadow-md" v-if="localChartOptions">
        <highcharts class="chart min-h-[200px] max-h-[300px]" :options="localChartOptions" ref="chartRef"></highcharts>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { ref, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import filters from '@/utils/filters'
import columns from '@/dashboards/herobar.json'
import { v4 as uuidv4 } from 'uuid'
import { getChartOptions } from '@/utils/chartOptions'
import {
  computedTitle
} from '@/utils/chartComputed'

const { t } = useI18n()
const store = useStore()

const isMd = computed(() => store.state.display.isMd)
const isLg = computed(() => store.state.display.isLg)
const isXl = computed(() => store.state.display.isXl)

const isMobileScreen = computed(() => !isLg.value && !isXl.value)

const column = ref(columns.data[0].columns[0])
const fetchOptions = ref(column.value.fetchOptions)

fetchOptions.value.start_date = filters.renderDate('start_date', fetchOptions.value.start_date)
fetchOptions.value.end_date = filters.renderDate('end_date', fetchOptions.value.end_date)

const fetchFunction = ref(column.value.fetchFunction)
const displayOptions = ref(column.value.displayOptions)
const title = computedTitle(displayOptions, fetchOptions, null)

const uuid = ref(uuidv4())
const series = ref([])
const categories = ref([])
const yAxisCategories = ref([])
const isLoading = ref(false)

const localChartOptions = getChartOptions(uuid, displayOptions, fetchOptions, series, categories, yAxisCategories)
const chartRef = ref(null)

// Function to fetch the results
const fetchData = async () => {
  if (isLoading.value) return
  isLoading.value = true

  // if not visible, or lazy loading is disabled and has been updated but not less then one minutes ago
  try {
    const response = await store.dispatch(fetchFunction.value, { payload: fetchOptions.value, displayOptions: displayOptions.value })
    let data = response.series 
    if (response.categories) categories.value = response.categories

    // Determine visibility of the card and series 
    if (data === null || data === undefined || data.length === 0) {
      // hideCard()
    } else {
      data = data.sort((a, b) => a.name.localeCompare(b.name))
      data.forEach(item => { item.visible = true })
    }
    series.value = data 
  } catch (error) {
    console.log(error)
  }

  isLoading.value = false
}

onMounted(() => {
  fetchData()
})
</script>
