// src/utils/helpers.js
export function isNullOrUndefined (value) {
  return value === null || value === undefined
}

export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  // Format to yyyy-mm-dd
  return `${year}-${month}-${day}`
}

export const formatTimestamp = (ts, showTime) => {
  const date = new Date(ts)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  if (showTime) {
    return `${year}-${month}-${day} ${hours}:${minutes}`
  }
  
  return `${year}-${month}-${day}`
}
