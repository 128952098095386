<template>
  <header class='flex shadow-md lg:py-4 pl-4 sm:pl-10 bg-white font-[sans-serif] min-h-[70px] tracking-wide relative sticky top-0 z-50 dark:bg-gray-800'>
    <div class="flex items-center justify-between w-full">
      <!-- Logo and menu container -->
      <div class="flex items-center">

        <router-link to="/">
          <span class="bg-clip-text text-transparent bg-gradient-to-br from-emerald-700 to-emerald-400 font-azonix text-2xl">
            Profiteia
          </span>
        </router-link>
        <div>
          <div class="flex invisible lg:visible ml-5" v-if="!isMobileScreen">
            <main-menu/>
          </div>
          <div class="flex visible lg:invisible absolute right right-2 top-4" v-if="isMobileScreen">
            <button data-collapse-toggle="navbar-sticky" type="button" class="flex p-2 w-10 h-10 justify-right text-sm text-black pr-0 pl-2" aria-controls="navbar-sticky" aria-expanded="false" @click="toggleMobileMenuVisible">
              <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 13">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <jb-button v-show="isAuthenticated && !isSubscribed" class="mx-2 invisible lg:visible" small to="/subscriptions" :label="t('menu_subscriptions')" color="success" noBorder />
    <jb-button v-show="isAuthenticated" to="/profile" class="invisible lg:visible" :label="userEmail" small color="white" noBorder/>
    <jb-button v-show="!isAuthenticated" class="mx-2 invisible lg:visible" small to="/register" :label="t('menu_register')" color="success" noBorder />
    <jb-button v-show="!isAuthenticated" class="invisible lg:visible" to="/login" :label="t('menu_login')" small color="white" noBorder/>
    <!--
    <nav-bar-menu has-divider class="hidden lg:flex">
      <div>
        <span>{{ currentLocale }}</span>
      </div>

      <template #dropdown>
        <nav-bar-item @click.prevent="setLocale('en')" has-divider v-if="$i18n.locale !== 'en'">
          <nav-bar-item-label :icon="mdiFlag" label="English"/>
        </nav-bar-item>
        <nav-bar-item @click.prevent="setLocale('nl')" has-divider v-if="$i18n.locale !== 'nl'">
          <nav-bar-item-label :icon="mdiFlag" label="Nederlands"/>
        </nav-bar-item>
        <nav-bar-item @click.prevent="setLocale('de')" has-divider v-if="$i18n.locale !== 'de'">
          <nav-bar-item-label :icon="mdiFlag" label="Deutsch"/>
        </nav-bar-item>
      </template>
    </nav-bar-menu>
    -->
    <!-- <nav-bar-item @click.prevent="toggleLightDark" has-divider class="hidden lg:flex">
      <nav-bar-item-label :icon="mdiThemeLightDark" :label="$t('menu_darkmode')" desktop-icon-only/>
    </nav-bar-item> -->

  </header>
  <div v-if="isMobileMenuVisible" class="lg:hidden items-center bg-white justify-between w-full md:flex md:w-auto md:order-1 sticky top-16 " id="navbar-sticky">
    <template v-for="(menuGroup, index) in menuMobile" :key="`m-${index}`">
      <mobile-menu-list :menu="menuGroup" isMobile/>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import JbButton from '@/components/JbButton'
import MainMenu from '@/components/navigation/MainMenu'
import NavBarItem from '@/components/NavBarItem'
import NavBarItemLabel from '@/components/NavBarItemLabel'
import NavBarMenu from '@/components/NavBarMenu'
import NavBarMenuDivider from '@/components/NavBarMenuDivider'
import MobileMenuList from '@/components/navigation/MobileMenuList'
import menuMobile from '@/menuMobile.js'
import { mdiFlag, mdiEarth, mdiThemeLightDark } from '@mdi/js'

const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

const setLocale = (locale) => {
  store.dispatch('display/setLocale', locale)
}

const isMd = computed(() => store.state.display.isMd)
const isLg = computed(() => store.state.display.isLg)
const isXl = computed(() => store.state.display.isXl)

const isMobileScreen = computed(() => !isLg.value && !isXl.value)

const isNavBarVisible = computed(() => !store.state.display.isFormScreen)
const isMobileMenuVisible = computed(() => store.state.display.isMobileMenuVisible)
const currentLocale = computed(() => t('language_short'))
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const isSubscribed = computed(() => store.state.auth.isSubscribed)
const userEmail = computed(() => store.state.auth.email)

const toggleMobileMenuVisible = () => store.dispatch('display/mobileMenuToggle')
const toggleLightDark = () => store.dispatch('display/darkMode')
</script>
