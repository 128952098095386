<template>
  <section class="bg-gradient-to-r from-white from-30% via-emerald-100 via-30% to-white to-70% dark:from-gray-600 dark:from-10% dark:via-emerald-600 dark:via-30% dark:to-gray-600 to-70% border-t border-b border-gray-100 p-6 dark:bg-gray-900 dark:border-gray-900 dark:text-white overflow-hidden">
    <div class="lg: pr-4 lg:pl-20 py-4 lg:py-8 mx-auto lg:py-16">
      <!-- Flex container to hold left, middle, and right sections -->
      <div class="h-full justify-start items-start w-full flex flex-col lg:flex-row">
        <!-- Left Section: Text and Buttons -->
        <div :class="{
              'h-80': isMobileScreen && !isAuthenticated,
              'h-64': isMobileScreen && isAuthenticated
            }"
            class="self-start lg:w-1/3 text-center lg:text-left">
          <h1 class="max-w-2xl mb-4 text-5xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
            {{ $t('general_tagline') }}
          </h1>
          <p class="max-w-xl mb-6 ml-2 font-normal text-black lg:mb-8 md:text-lg lg:text-xl dark:text-gray-200">
            {{ $t('general_tagline_description') }}
          </p>
          <field v-show="!isMobileScreen && !isAuthenticated">
            <control name="email" type="email" :placeholder="$t('general_email')" autocomplete="email" required v-model="contactForm.email" borderless/>
            <jb-button color="success" class="w-40" :label="$t('menu_register')" @click="proceedToNavigation()"/>
          </field>
          <jb-button v-show="isMobileScreen && !isAuthenticated" color="success" @click="proceedToNavigation()" class="w-40" :label="$t('menu_register')" />
          <hero-bar-items/>
        </div>
        <!-- Middle Section: Empty (no content as per image) -->
        <div class="flex-grow w-1/2 lg:w-1/3 text-center"></div>
        <hero-bar-chart-card/>
      </div>
    </div>
  </section>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Field from '@/components/Field'
import Control from '@/components/Control'
import JbButton from '@/components/JbButton'
import SampleChart from '@/components/charts/SampleChart'
import HeroBarItems from '@/components/HeroBarItems'
import HeroBarChartCard from '@/components/HeroBarChartCard'

const { t } = useI18n()
const store = useStore()
const router = useRouter()

const isMd = computed(() => store.state.display.isMd)
const isLg = computed(() => store.state.display.isLg)
const isXl = computed(() => store.state.display.isXl)

const isMobileScreen = computed(() => !isLg.value && !isXl.value)
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)

const contactForm = reactive({
  firstname: '',
  lastname: '',
  email: store.state.auth.userEmail
})

const proceedToNavigation = () => {
  if (contactForm.email) {
    router.push({ name: 'register', query: { userEmail: contactForm.email } })
  } else {
    router.push({ name: 'register' })
  }
}
</script>
