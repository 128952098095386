<template>
  <component :is="is" :to="to" :href="href" :target="target" class="cursor-pointer" :class="{'underline': underline, 'text-left': alignLeft, 'items-center': centered, 'inline-flex': flex, 'text-sm': small, 'text-xs': xsmall}">
    <icon v-if="icon" :path="icon" w="w-4" h="h-4" :class="[small ? 'mr-1' : 'mr-2']"/>
    <span>
      {{ label }}
    </span>
  </component>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon'
import Pill from '@/components/Pill'

export default {
  name: 'TextLink',
  components: {
    Icon,
    Pill
  },
  props: {
    to: String,
    href: String,
    icon: String,
    label: String,
    isPro: Boolean,
    small: Boolean,
    xsmall: Boolean,
    underline: {
      type: Boolean,
      default: true
    },
    flex: {
      type: Boolean,
      default: true
    },
    alignLeft: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: true
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  setup (props) {
    const is = computed(() => props.to ? 'router-link' : 'a')

    return {
      is
    }
  }
}
</script>
