<template>
  <nav-bar-menu has-divider> <!-- v-if="!isAuthenticated"> -->
    <nav-bar-item-label :label="$t('menu_markets')"/>
    <template #dropdown>
      <nav-bar-item to="/">
        <nav-bar-item-label :label="$t('menu_home')"/>
      </nav-bar-item>
      <nav-bar-item to="/reports/imbalance">
        <nav-bar-item-label :label="$t('menu_imbalance')"/>
      </nav-bar-item>
      <nav-bar-item to="/reports/outlook">
        <nav-bar-item-label :label="$t('menu_energy_outlook')"/>
      </nav-bar-item>
      <!-- <nav-bar-item to="/reports/imbalance-prediction">
        <nav-bar-item-label :label="$t('menu_imbalance_prediction')"/>
      </nav-bar-item> -->
      <!-- <nav-bar-item to="/reports/gas">
        <nav-bar-item-label :label="$t('menu_gas')"/>
      </nav-bar-item>
      <nav-bar-item to="/reports/oil">
        <nav-bar-item-label :label="$t('menu_oil')"/>
      </nav-bar-item> -->
      <!-- <nav-bar-menu-divider/>
      <nav-bar-item to="/login">
        <nav-bar-item-label :label="$t('menu_login')"/>
      </nav-bar-item> -->
    </template>
  </nav-bar-menu>
  <nav-bar-menu>
    <nav-bar-item-label :label="$t('menu_batteries_dropdown')"/>
    <template #dropdown>
      <nav-bar-item to="/reports/batteries">
        <nav-bar-item-label :label="$t('menu_batteries')"/>
      </nav-bar-item>
      <nav-bar-item to="/reports/battery-simulation">
        <nav-bar-item-label :label="$t('menu_battery_simulation')"/>
      </nav-bar-item>
    </template>
  </nav-bar-menu>
  <nav-bar-item to="/reports/outlook">
    <nav-bar-item-label :label="$t('menu_energy_outlook')"/>
  </nav-bar-item>
  <nav-bar-item to="/about">
    <nav-bar-item-label :label="$t('menu_about_us')"/>
  </nav-bar-item>
  <nav-bar-item to="/contact">
    <nav-bar-item-label :label="$t('menu_contact')"/>
  </nav-bar-item>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiHome, mdiAccount, mdiInformationOutline, mdiEmailOpen, mdiLogin, mdiGasCylinder, mdiPowerPlug, mdiBarrel, mdiMoleculeCo2, mdiChartAreaspline } from '@mdi/js'
import NavBarItem from '@/components/NavBarItem'
import NavBarItemLabel from '@/components/NavBarItemLabel'
import NavBarMenu from '@/components/NavBarMenu'
import NavBarMenuDivider from '@/components/NavBarMenuDivider'
import { useStore } from 'vuex'

const { t } = useI18n()
const store = useStore()
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)

</script>
