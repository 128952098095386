// src/utils/chart.js
import { isNullOrUndefined } from './helpers'
import { svgPathWatermark } from './constants/svgPathWatermark'
import { useStore } from 'vuex'
import store from '@/store'

// Local helper functions
const watermarkDimensions = (dimensions) => {
  dimensions.svgPosX = 0
  dimensions.svgPosY = 0
  dimensions.svgWidth = 137 * 2
  dimensions.svgHeight = 16.79 * 2

  // const heightMultiplier = 0.55
  // let widthMultiplier = 3.0
  const xMargin = 10
  const yMargin = 10

  // // Height should always be the same, use different proportions for sizes
  // if (dimensions.chartWidth > 800) {
  //   widthMultiplier = 3.4
  //   xMargin = 0.02
  //   yMargin = 0.08
  // } else if (dimensions.chartWidth > 400) {
  //   widthMultiplier = 2.6
  //   xMargin = 0.02
  //   yMargin = 0.10
  // } else {
  //   widthMultiplier = 2.2
  //   xMargin = 0.06
  //   yMargin = 0.05
  // }
  // text
  dimensions.svgPosX = parseInt(dimensions.xAxisTitleMargin) + parseInt(dimensions.xAxisLeft)
  dimensions.svgPosY = dimensions.yAxisHeight - dimensions.svgHeight
  dimensions.svgMaxY = (dimensions.chartHeight) - (dimensions.svgHeight)
  // console.log(dimensions)
  return dimensions
}

export const addWatermark = (chart) => {
  const dimensions = watermarkDimensions({
    chartWidth: chart.chartWidth,
    chartHeight: chart.chartHeight,
    yAxisHeight: chart.yAxis[0].height,
    xAxisWidth: chart.xAxis[0].width,
    xAxisTitleMargin: chart.xAxis[0].axisTitleMargin,
    xAxisLeft: chart.xAxis[0].left
  })

  if (chart.xAxis[0].axisTitleMargin === undefined) {
    return
  }
  // chart.watermark = chart.renderer.image('/images/profiteia_logo_text.svg', dimensions.svgPosX, dimensions.svgPosY, dimensions.svgWidth, dimensions.svgHeight).add()
  const transform = `translate(${dimensions.svgPosX}, ${dimensions.svgPosY}) scale(0.13, 0.13)`

  if (chart.watermark) {
    chart.watermark.attr({ fill: '#A9A9A9', transform: transform, zIndex: 100 })
  } else {
    chart.watermark = chart.renderer.path(svgPathWatermark).attr({
      fill: '#A9A9A9',
      transform: transform,
      zIndex: 100
    }).add()
  }
}

export const syncChartDimensions = (updatedRef, displayOptions, store, uuid) => {
  if (
    isNullOrUndefined(updatedRef) ||
    isNullOrUndefined(displayOptions.value.syncWhat)) {
    return
  }

  displayOptions.value.syncWhat.forEach(function (syncWhatDimension, syncWhatIndex) {
    if (
      isNullOrUndefined(updatedRef.chart) ||
      isNullOrUndefined(updatedRef.chart.xAxis) ||
      isNullOrUndefined(updatedRef.chart.yAxis)) {
      return
    }

    const extremesY = updatedRef.chart.yAxis[0].getExtremes()
    const extremesX = updatedRef.chart.xAxis[0].getExtremes()

    if (
      isNullOrUndefined(extremesY.min) ||
      isNullOrUndefined(extremesY.max) ||
      isNullOrUndefined(extremesX.min) ||
      isNullOrUndefined(extremesX.max)
    ) {
      return
    }

    // console.log('check1', syncWhatIndex, syncWhatDimension, extremesX, extremesY)
    // Get values for syncWhatDimension
    const syncValues = store.getters['display/getSyncValues']({
      uuid: uuid,
      syncId: displayOptions.value.syncId,
      syncWhat: syncWhatDimension
    })

    // console.log(syncValues)

    // Make sure chart is initalized
    if (syncValues.length > 0) {
      const min = Math.min(...syncValues)
      const max = Math.max(...syncValues)

      // console.log('Found min: ', min, ' and max: ', max)
      if (syncWhatDimension === 'yMin' && extremesY.min !== min) {
        updatedRef.chart.yAxis[0].setExtremes(min, extremesY.max)
        updatedRef.chart.redraw()
      } else if (syncWhatDimension === 'yMax' && extremesY.max !== max) {
        // console.log('Updating extremesY.max for ', uuid, ' to gofrom: ', extremesY.max, ' to: ', max)
        updatedRef.chart.yAxis[0].setExtremes(extremesY.min, max)
        updatedRef.chart.redraw()
      } else if (syncWhatDimension === 'xMin' && extremesX.min !== min) {
        // console.log('Updating extremesX.min for ', uuid, ' to gofrom: ', extremesX.min, ' to: ', min)
        updatedRef.chart.xAxis[0].setExtremes(min, extremesX.max)
        updatedRef.chart.redraw()
      } else if (syncWhatDimension === 'xMax' && extremesX.max !== max) {
        // console.log('Updating extremesX.max for ', uuid, ' to gofrom: ', extremesX.max, ' to: ', max)
        updatedRef.chart.xAxis[0].setExtremes(extremesX.min, max)
        updatedRef.chart.redraw()
      }
    }
  })
}

export const syncChart = ({ uuid, displayOptions, syncDimensions }) => {
  if (!('syncWhat' in displayOptions.value) || !('syncId' in displayOptions.value)) {
    return
  }

  store.dispatch('display/syncChart', {
    uuid,
    syncId: displayOptions.value.syncId,
    syncWhat: displayOptions.value.syncWhat,
    syncDimensions
  })
}
