<template>
  <Listbox v-model="computedValue" as="div" v-slot="{ open }">
    <ListboxButton :class="[buttonClass, open ? 'ring' : '']" class="text-left ring-blue-700 min-w-96">
      <span v-if="computedValue.length === 0" class="text-gray-500">Select</span>
      <span v-else-if="computedValue.length === 1" class="truncate">{{ computedValue[0].name }}</span>
      <span v-else class="truncate">{{ computedValue[0].name }} (+{{ computedValue.length - 1 }})</span>
    </ListboxButton>
    <ListboxOptions class="absolute z-20 min-w-96 bg-white p-2 ">
      <ListboxOption v-for="option in options" :key="option.id" :value="option">
        <li :class="{ 'listbox-multi-option cursor-pointer p-1 font-medium': option.visible, 'cursor-pointer text-black p-1': !option.visible }">{{ option.name }}</li>
      </ListboxOption>
    </ListboxOptions>
  </Listbox>
</template>

<script setup>
import { ref, watch, watchEffect, computed, defineProps, defineEmits } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'

const props = defineProps({
  options: Array,
  modelValue: Object,
  buttonClass: [String, Array]
})

const emit = defineEmits(['update:modelValue'])

const computedValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:modelValue', value)
  }
})

</script>
