<template>
  <form-screen-section bg="gas" v-slot="{ cardClass, cardRounded }">
    <card-component
      :class="[cardClass, cardClassAddon]"
      :rounded="cardRounded"
      @submit.prevent="submit"
      form
    >

      <field :label="$t('general_subject')">
          <control v-model="contactForm.contactSubject" name="subject" required/>
        </field>
        <field :label="$t('general_name')" :help="$t('general_name_help_required')">
          <control v-model="contactForm.contactFromName" name="name" autocomplete="name" required/>
        </field>
        <field :label="$t('general_email')" :help="$t('general_email_help_required')">
          <control v-model="contactForm.contactFromEmail" name="email" type="email" autocomplete="email" required/>
        </field>
        <field :label="$t('general_question')" :help="$t('general_question_help')">
          <control type="textarea" :placeholder="$t('general_question_placeholder')" name="message" v-model="contactForm.contactMessage" required/>
        </field>
        <field label="Captcha" :help="$t('general_captcha_help')">
          <template v-slot:labelIcon>
            <icon :path="mdiReload" size="12" @click="getCaptcha()"/>
          </template>
          <control v-model="contactForm.captchaResult" name="name" autocomplete="name" required/>
        </field>
        <img v-if="captchaData !== ''" :src="`data:image/png;base64,${captchaData}`" />
        <!-- key: {{ contactForm.captchaKey }} -->
        <input type="hidden" name="captchaKey" v-model="contactForm.captchaKey"/>
        <template #footer>
          <level>
            <jb-buttons>
              <jb-button type="submit" :label="$t('general_send')" color="info" @click="submitContactForm()" />
            </jb-buttons>
          </level>
        </template>
    </card-component>
  </form-screen-section>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { mdiReload } from '@mdi/js'
import FormScreenSection from '@/components/FormScreenSection'
import MainSection from '@/components/MainSection'
import CardComponent from '@/components/CardComponent'
import TweetsCard from '@/components/TweetsCard'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Icon from '@/components/Icon'
import Field from '@/components/Field'
import Control from '@/components/Control'
import JbButtons from '@/components/JbButtons'
import JbButton from '@/components/JbButton'
import Level from '@/components/Level'
import Repository from '@/repositories/RepositoryFactory'

const { t } = useI18n()
const store = useStore()
const EmailRepository = Repository.get('email')
const captchaData = ref('')
const isLoading = ref(false)
const errorMessage = ref('')
const cardClassAddon = computed(() => errorMessage.value !== '' ? 'animate-shake' : '')

const contactForm = reactive({
  captchaKey: null,
  captchaResult: null,
  contactFromName: null,
  contactFromEmail: null,
  contactSubject: null,
  contactMessage: null
})

const getCaptcha = () => {
  isLoading.value = true

  EmailRepository.getCaptcha()
    .then(response => {
      captchaData.value = response.data.captcha_data
      isLoading.value = false
      contactForm.captchaKey = response.data.key
    })
    .catch(error => {
      console.log(error)
    })
}

const submitContactForm = () => {
  isLoading.value = true

  setTimeout(() => {
    EmailRepository.submitContactForm(contactForm)
      .then(response => {
        store.dispatch('display/pushMessage', t('general_message_sent'))

        contactForm.captchaKey = null
        contactForm.captchaResult = null
        contactForm.contactFromName = null
        contactForm.contactFromEmail = null
        contactForm.contactSubject = null
        contactForm.contactMessage = null

        getCaptcha()
      })
      .catch(error => {
        store.dispatch('display/pushMessage', t(error.response.data.detail))
      })
      .finally(() => {
        isLoading.value = false
      })
  }, 500)
}

onMounted(() => {
  getCaptcha()
})
</script>
