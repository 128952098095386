import Repository from '@/repositories/RepositoryFactory'
import i18n from '@/i18n'
import filters from '@/utils/filters'

const debug = true
const AuthRepository = Repository.get('auth')

function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

function isValidJwt (token) {
  if (!token || token.split('.').length < 3) {
    return { status: false, reason: 'tokenInvalid' }
  }

  const data = parseJwt(token)
  if (data.exp < Date.now() / 1000) {
    return { status: false, reason: 'tokenExpired' }
  } else {
    return { status: true, reason: 'tokenValid' }
  }
}

export default {
  namespaced: true,
  state: {
    /* User Old */
    userName: null,
    userEmail: null,
    userAvatar: null,
    /* User New */
    email: null,
    phonenumber: null,
    company: null,
    firstname: null,
    lastname: null,
    createdOn: null,
    lastLogin: null,
    receiveNewsletter: false,
    requiresTwoFactor: false,
    subscription: false,
    isSubscribed: false,
    isSuperUser: false,
    subscriptionPlan: null,
    isAuthenticated: false,
    visitorId: null,
    isFetchingUser: false,
    routeChange: null
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },
    clearTokens (state) {
      sessionStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    },
    clearUserData (state) {
      state.email = null
      state.firstname = null
      state.lastname = null
      state.createdOn = null
      state.lastLogin = null
      state.receiveNewsletter = false
      state.isSubscribed = false
      state.isSuperUser = false
      state.isAuthenticated = false
      state.requiresTwoFactor = false
    },
    setAccessToken (state, payload) {
      sessionStorage.setItem('accessToken', payload.accessToken)
      const tokenStatus = isValidJwt(payload.accessToken)
      state.isAuthenticated = tokenStatus.status
    },
    setRefreshToken (state, payload) {
      // console.log('settingRefreshToken', payload.refreshToken)
      localStorage.setItem('refreshToken', payload.refreshToken)
    },
    setRouteChange (state, payload) {
      state.routeChange = payload
    },
    setFetchingUser (state, payload) {
      // console.log('setFetchingUser = ', payload)
      state.isFetchingUser = payload
    },
    setIsAuthenticated (state, payload) {
      state.isAuthenticated = payload
    },
    setUserData (state, payload) {
      if (payload.firstname) {
        state.firstname = payload.firstname
      }
      if (payload.lastname) {
        state.lastname = payload.lastname
      }
      if (payload.email) {
        state.email = payload.email
      }
      if (payload.phonenumber) {
        state.phonenumber = payload.phonenumber
      }
      if (payload.createdOn) {
        state.createdOn = payload.createdOn
      }
      if (payload.lastLogin) {
        state.lastLogin = payload.lastLogin
      }
      if (payload.subscription) {
        state.isSubscribed = true
        state.subscription = payload.subscription
        // state.subscriptionPlan = filters.getSubscriptionPlanByID(payload.subscription.plan_id)
      } else {
        state.isSubscribed = false
        state.subscription = null
        state.subscriptionPlan = null
      }
      if (payload.isSuperUser) {
        state.isSuperUser = payload.isSuperUser
      }
      if (payload.receiveUpdates) {
        state.receiveNewsletter = payload.receiveNewsletter
      }
      if ('receiveNewsletter' in payload) {
        state.receiveNewsletter = payload.receiveNewsletter
      }
      if (payload.requiresTwoFactor) {
        state.requiresTwoFactor = payload.requiresTwoFactor
      }
      if (payload.company) {
        state.company = payload.company
      }

      state.isAuthenticated = true
    },
    setVisitorId (state, visitorId) {
      // console.log('setting visitorId', visitorId)
      sessionStorage.setItem('visitorId', visitorId)
      localStorage.setItem('visitorId', visitorId)
      state.visitorId = visitorId
    },
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    }
  },
  actions: {
    storeRouteChange ({ commit, state }) {
      if (localStorage.getItem('visitorId') === 'null' || state.routeChange === null) {
        return false
      }

      AuthRepository.storeRouteChange({
        routeChange: state.routeChange,
        email: state.email,
        publicAddress: state.publicAddress,
        visitorId: localStorage.getItem('visitorId')
      }).then(response => {
        commit('setRouteChange', null)
      }).catch((error) => {
        debug && console.log('storeRouteChange: ', error)
      })
    },
    getUserInformation ({ commit, dispatch, state }) {
      if (sessionStorage.getItem('accessToken') && isValidJwt(sessionStorage.getItem('accessToken'))) {
        debug && console.log('valid accessToken')
        commit('setIsAuthenticated', true)
      }

      if (!sessionStorage.getItem('accessToken') && !localStorage.getItem('refreshToken')) {
        debug && console.log('called getUserInformation, returning false because no accessToken and no refreshToken')
        return false
      }

      if (state.isFetchingUser) {
        debug && console.log(`called getUserInformation, state.isFetchingUser: ${state.isFetchingUser}`)
        return false
      }

      debug && console.log(`Called getUserInformation ${sessionStorage.getItem('accessToken')} and  ${localStorage.getItem('refreshToken')}`)
      commit('setFetchingUser', true)

      AuthRepository.userInformation().then(response => {
        if (response) {
          commit('setUserData', {
            public_address: response.data.public_address,
            email: response.data.email,
            isSuperUser: response.data.is_superuser,
            subscription: response.data.subscription,
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            createdOn: response.data.created_on,
            lastLogin: response.data.last_login,
            receiveUpdates: response.data.receive_updates,
            receiveNewsletter: response.data.receive_newsletter,
            requiresTwoFactor: response.data.requires_two_factor,
            company: response.data.company,
            phonenumber: response.data.phonenumber
          })

          if (isValidJwt(sessionStorage.getItem('accessToken'))) {
            commit('setIsAuthenticated', true)
          }

          return true
        }
      }).catch((error) => {
        debug && console.log('Session Expired: ', error)

        sessionStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')

        dispatch('display/pushMessage', i18n.global.t('messages.session_expired'), { root: true })
        return false
      }).finally(() => {
        commit('setFetchingUser', false)
      })
    },
    async logoutUser (state) {
      await AuthRepository.logout().then().catch(function (error) {
        if (error !== undefined) {
          debug && console.log(error)
        }
      })
    }
  },
  getters: {
    visitorId (state) {
      if (state.visitorId !== null) {
        sessionStorage.setItem('visitorId', state.visitorId)
        localStorage.setItem('visitorId', state.visitorId)
      }

      return state.visitorId
    },
    isFetchingUser (state) {
      return state.isFetchingUser
    }
  }
}
