<template>
  <main-section class="min-h-400" :class="componentClass">
    <div class="grid grid-cols-1 xl:grid-cols-4 gap-9 px-4 py-3  ">
      <div>
        <!-- Breadcrumb -->
        <div class="flex items-center space-x-2 pl-1">
          <text-link to="/" :icon="mdiHome" :underline="false" :label="$t('menu_home')" small/>
          <span class="text-sm text-gray-500 dark:text-gray-400">/</span>
          <span class="text-sm text-gray-500 dark:text-gray-400">{{ route.meta.title }}</span>
        </div>
        <h1 class="text-5xl pt-5 font-bold text-black">{{ route.meta.title }}</h1>
      </div>
    </div>
    <div class="flex items-center md:flex-row md:justify-around min-h-400">
      <slot card-class="w-10/12 md:w-7/12 lg:w-4/12 xl:w-3/12 border-none bg-transparent"/>
    </div>
  </main-section>
  <!--
  <main-section
    class="flex items-center md:flex-row md:justify-around min-h-400 pt-20"
    :class="componentClass"
  >
    <slot card-class="w-10/12 md:w-7/12 lg:w-4/12 xl:w-4/12 border-none bg-transparent"/>

    <div v-if="promo" class="md:hidden space-y-6 py-6">
      <div class="text-white">
        <router-link to="/">
          <profiteia-logo-text class="w-auto h-8 mx-auto" />
        </router-link>
      </div>
    </div>
  </main-section>
  -->
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import MainSection from '@/components/MainSection'
import ProfiteiaLogoText from '@/components/ProfiteiaLogoText'
import Icon from '@/components/Icon'
import { mdiHome } from '@mdi/js'
import TextLink from '@/components/TextLink'
import TitleBar from '@/components/TitleBar'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  bg: String,
  promoTitle: String,
  promoSubtitle: String,
  promo: Boolean
})
const { t } = useI18n()
const store = useStore()
const titleStack = ref([])
const router = useRoute()

const currentPage = router.name

titleStack.value = [t('menu_home')]

const darkMode = computed(() => store.state.display.darkMode)

const componentClass = computed(() => {
  return 'bg-gradient-to-b from-emerald-100 from-10% via-white via-20% to-white to-70% border-t border-b border-gray-100 p-6 dark:bg-gray-900 dark:border-gray-900'
})

const route = useRoute()

const currentRouteName = route.name

</script>
