<template>
  <li>
    <component
      v-if="item.label && (!('auth' in item) || (item.auth && isAuthenticated) || (!item.auth && !isAuthenticated) || (item.admin && isSuperUser))"
      :is="componentIs"
      :to="itemTo"
      :href="itemHref"
      :exact-active-class="exactActiveClass"
      class="flex cursor-pointer pl-3"
      :class="componentClass"
      @click="menuClick"
    >
      <icon
        v-if="item.icon && !isMobile"
        :path="item.icon"
        :update-mark="item.updateMark ?? null"
        :update-mark-tr="updateMarkTr"
        class="flex-none p-0"
        :w="menuIconWidth"
      />
      <span class="flex-grow pr-0 top-menu-span">{{ t(item.label) }}</span>
    </component>
  </li>
</template>

<script setup>
import { defineAsyncComponent, defineProps, defineEmits, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Icon from '@/components/Icon'
import { useI18n } from 'vue-i18n'
import { colorsButtons } from '@/colors.js'

// Props
const props = defineProps({
  key: Number,
  item: Object,
  isSubmenuList: Boolean,
  compact: Boolean,
  isMobile: {
    type: Boolean,
    default: false
  }
})

// Emits
const emit = defineEmits(['menu-click'])

// Composables
const { t } = useI18n()
const store = useStore()
const router = useRouter()

// State and Computed Properties
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const isSuperUser = computed(() => store.state.auth.isSuperUser)
const isSubscribed = computed(() => store.state.auth.isSubscribed)

const isDropdownActive = ref(false)
const isSecondaryMenuActive = computed(() => secondaryMenu.value && props.item && props.item.key === secondaryMenu.value.key)
const secondaryMenu = computed(() => store.state.display.secondaryMenu)
const hasColor = computed(() => props.item && props.item.color && colorsButtons[props.item.color])

const activeClass = 'text-azure border-b-2 border-azure'
const activeClassMobile = 'text-emerald bg-azure rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500'

const componentClass = computed(() => {
  if (props.isMobile) {
    const base = 'py-2'
    if (!hasColor.value && (isDropdownActive.value || isSecondaryMenuActive.value)) {
      base.push(activeClassMobile)
    }
    return base
  } else {
    const base = [
      props.isSubmenuList ? 'p-3 text-sm' : 'py-4 pr-8',
      itemFirst.value ? 'ml-3' : '',
      hasColor.value ? colorsButtons[props.item.color] : 'text-black hover:text-azure'
    ]
    if (!hasColor.value && (isDropdownActive.value || isSecondaryMenuActive.value)) {
      base.push(activeClass)
    }
    return base
  }
})

const exactActiveClass = computed(() => {
  if (secondaryMenu.value === null) {
    if (props.isMobile) {
      return activeClassMobile
    }
    return activeClass
  }
  return null
})

const componentIs = computed(() => (props.item.to ? 'router-link' : 'a'))
const itemFirst = computed(() => props.item.first ?? false)
const hasDropdown = computed(() => !!props.item.menu)
const hasSecondary = computed(() => !!props.item.menuSecondary)
const hasSub = computed(() => hasDropdown.value || hasSecondary.value)
const itemTo = computed(() => props.item.to || null)
const itemHref = computed(() => props.item.href || null)
const menuIconWidth = computed(() => (props.compact ? 'w-8' : 'w-8'))
const updateMarkTr = computed(() => {
  const base = 'top-0 right-2'
  return props.compact ? `${base} lg:right-6` : base
})

const menuClick = (event) => {
  if ('action' in props.item && props.item.action === 'doLogout') {
    store.commit('auth/clearTokens')
    store.commit('auth/clearUserData')
    store.dispatch('display/pushMessage', t('messages.logged_out'))
    router.push('/account/login')
  } else if ('action' in props.item && props.item.action === 'showNewsletter') {
    store.dispatch('display/newsletterModalToggle', true)
  } else {
    emit('menu-click', event, props.item)
  }
  if (props.isMobile) {
    store.dispatch('display/mobileMenuToggle')
  }
  if (hasDropdown.value) {
    isDropdownActive.value = !isDropdownActive.value
  }
}

const isVisible = computed(() => {
  return (
    (props.item.auth === undefined ||
      (props.item.auth && isAuthenticated.value) ||
      (!props.item.auth && !isAuthenticated.value)) &&
    (props.item.subscribed === undefined ||
      (props.item.subscribed && isSubscribed.value) ||
      (!props.item.subscribed && !isSubscribed.value)) &&
    (props.item.admin === undefined ||
      (props.item.admin && isSuperUser.value) ||
      (!props.item.admin && !isSuperUser.value))
  )
})
</script>
