// import router from './router'
import i18n from '@/i18n'
import {
  mdiAccount,
  mdiAccountCircle,
  mdiLogin,
  mdiChartAreaspline,
  mdiBook,
  mdiHome,
  mdiEmailOpen,
  mdiCogOutline,
  mdiBattery,
  mdiCreditCardCheck,
  mdiBatteryHeartVariant,
  mdiBellAlert,
  mdiBatteryAlert
} from '@mdi/js'

export default [
  [
    {
      to: '/',
      label: 'menu_home',
      icon: mdiHome
    },
    {
      to: '/reports/outlook',
      icon: mdiChartAreaspline,
      label: 'menu_energy_outlook'
    },
    {
      to: '/reports/imbalance',
      icon: mdiBattery,
      label: 'menu_imbalance'
    },

    {
      to: '/reports/batteries',
      icon: mdiBattery,
      label: 'menu_batteries'
    },
    {
      to: '/reports/battery-simulation',
      icon: mdiBatteryHeartVariant,
      label: 'menu_battery_simulation'
    },
    {
      to: '/login',
      icon: mdiLogin,
      label: 'menu_login',
      auth: false
    },
    {
      to: '/profile',
      icon: mdiAccountCircle,
      label: 'menu_profile',
      auth: true
    },
    {
      to: '/about',
      label: 'menu_about_us',
      icon: mdiBook
    },
    {
      to: '/contact',
      icon: mdiEmailOpen,
      label: 'menu_contact'
    },
    {
      to: '/admin',
      icon: mdiCogOutline,
      label: 'menu_admin',
      auth: true,
      admin: true
    }
  ]
]
