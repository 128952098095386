<template>
  <router-link to="/" class="block">
    {{ t('menu_home') }}
  </router-link>
  <router-link to="/reports/outlook" class="block">
    {{ t('menu_energy_outlook') }}
  </router-link>
  <router-link to="/about" class="block">
    {{ t('menu_about_us') }}
  </router-link>
  <router-link to="/contact" class="block">
    {{ t('menu_contact') }}
  </router-link>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const { t } = useI18n()
const store = useStore()
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)

</script>
