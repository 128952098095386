<template>
  <div v-show="isNewsletterBarVisible && !receiveNewsletter" class="bg-zinc-50 py-20 px-6 font-[sans-serif]">
    <div class="max-w-6xl mx-auto text-center">
      <h2 class="text-5xl font-bold mb-2 text-black">{{ t('newsletter_call_to_action') }}</h2>
      <p class="text-base text-black">{{ t('newsletter_call_to_action_tagline') }}</p>

      <field horizontal class="mt-10">
        <control name="email" type="email" :placeholder="t('general_email_help_required')" autocomplete="email" required  v-model="contactForm.email"/>
        <jb-button color="success" class="w-40" :label="$t('menu_subscribe')" @click="confirm" />
      </field>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import JbButton from '@/components/JbButton'
import Control from '@/components/Control'
import Field from '@/components/Field'
import Repository from '@/repositories/RepositoryFactory'
import { useI18n } from 'vue-i18n'

const store = useStore()
const isNewsletterBarVisible = computed(() => !store.state.display.isFormScreen)
const receiveNewsletter = computed(() => store.state.auth.receiveNewsletter)
const { t } = useI18n()
const EmailRepository = Repository.get('email')

const contactForm = reactive({
  email: store.state.auth.userEmail
})
const isLoading = ref(false)

const confirm = (event, item) => {
  if (!contactForm.email) {
    store.dispatch('display/pushMessage', t('messages.email_required'))
    return
  }

  isLoading.value = true
  setTimeout(() => {
    isLoading.value = false

    EmailRepository.subscribeNewsletter(contactForm).then(response => {
      store.dispatch('display/pushMessage', t('messages.newsletter_subscription_success'))
      contactForm.email = null
    }).catch((error) => {
      store.dispatch('display/pushMessage', error.response.data.detail)
    })
  }, 500)
}

</script>
