<template>
  <div class="flex justify-left space-x-4 lg:mt-20 invisible lg:visible" >
    <!-- First item: Positive value -->
     <div class="text-center min-w-fit" v-for="(item, index) in transformedItems" :key="index">
      <div class="text-lg font-semibold text-black dark:text-gray-200">{{ item.name }}</div>
      <div class="text-sm text-black dark:text-gray-300">&euro; {{ item.FY }}
        <span v-if="item.FY_ABS < 0" class="text-green-500 text-xs" >
          {{ item.FY_ABS.toFixed(2) }} ({{ item.FY_PCT.toFixed(2) }}%) ▲
        </span>
        <span v-else-if="item.FY_ABS > 0" class="text-red-500 text-sm" >
          +{{ item.FY_ABS.toFixed(2) }} ({{ item.FY_PCT.toFixed(2) }}%) ▼
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Field from '@/components/Field'
import Control from '@/components/Control'
import JbButton from '@/components/JbButton'
import SampleChart from '@/components/charts/SampleChart'
import Repository from '@/repositories/RepositoryFactory'

const StatisticsRepository = Repository.get('statistics')

const { t } = useI18n()
const store = useStore()

const isMd = computed(() => store.state.display.isMd)
const isLg = computed(() => store.state.display.isLg)
const isXl = computed(() => store.state.display.isXl)

const isMobileScreen = computed(() => !isLg.value && !isXl.value)
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)

const items = ref([])
const transformedItems = computed(() => 
  Object.entries(items.value).map(([name, details]) => ({
    name,
    ...details
  }))
)

const retrieveHighlightedItems = async () => {
  const response = await StatisticsRepository.getHighlightedItems()
  items.value = response.data
}

onMounted(() => {
  retrieveHighlightedItems()
})

</script>
