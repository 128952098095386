<template>
  <aside
    v-show="isAsideVisible"
    class="flex flex-col w-60 h-screen top-0 fixed bg-gray-800 transition-position overflow-y-scroll
    scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-600 scrollbar-track-gray-900 dark:scrollbar-track-gray-800
    dark:bg-gray-900"
    :class="asideClassList"
  >
    <div
      class="flex flex-row w-full bg-gray-900 text-white flex-shrink-0 h-14 items-center"
      :class="{'lg:justify-center':labelCenter}"
    >
      <slot/>
    </div>
    <div class="flex-1">
      <template v-for="(menuGroup, index) in menu">
        <p
          v-if="typeof menuGroup === 'string'"
          :key="`h-${index}`"
          class="p-3 text-xs uppercase text-gray-400"
          :class="{'lg:hidden':compact}"
        >
          {{ menuGroup }}
        </p>
        <aside-menu-list
          v-else
          :key="`m-${index}`"
          :menu="menuGroup"
          :compact="compact"
          @menu-click="menuClick"
        />
      </template>
    </div>

    <aside-menu-list
      :menu="menuBottom"
      :compact="compact"
      @menu-click="menuClick"
    />
  </aside>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AsideMenuList from '@/components/AsideMenuList'

export default {
  name: 'AsideMenu',
  components: { AsideMenuList },
  emits: ['menu-click'],
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    menuBottom: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: String,
      default: 'z-50'
    },
    labelCenter: Boolean,
    compact: Boolean
  },
  setup (props, { emit }) {
    const store = useStore()

    const isAsideVisible = computed(() => store.state.display.isAsideVisible)
    const asideClassList = computed(() => [props.zIndex])

    const menuClick = (event, item) => {
      emit('menu-click', event, item)
    }

    return {
      isAsideVisible,
      asideClassList,
      menuClick
    }
  }
}
</script>
