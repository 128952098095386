// src/utils/constants/selectableCountriesBySource.js
export const selectableCountriesBySource = {
  'GIE Countries': [
    { label: 'NL', value: 'NL' },
    { label: 'BE', value: 'BE' },
    { label: 'DE', value: 'DE' },
    { label: 'FR', value: 'FR' },
    { label: 'IT', value: 'IT' },
    { label: 'AT', value: 'AT' },
    { label: 'DK', value: 'DK' },
    { label: 'ES', value: 'ES' },
    { label: 'PL', value: 'PL' },
    { label: 'PT', value: 'PT' }
  ],
  'ENTSO-E': [
    { label: 'NL', value: 'NL' },
    { label: 'BE', value: 'BE' },
    { label: 'DE', value: 'DE' },
    { label: 'FR', value: 'FR' },
    { label: 'IT', value: 'IT' },
    { label: 'PL', value: 'PL' },
    { label: 'AT', value: 'AT' },
    { label: 'CH', value: 'CH' },
    { label: 'ES', value: 'ES' },
    { label: 'PT', value: 'PT' },
    { label: 'DK', value: 'DK' },
    { label: 'NO', value: 'NO' },
    { label: 'SE', value: 'SE' },
    { label: 'FI', value: 'FI' }
  ],
  'ICE Power Base': [
    { label: 'NL', value: 'Dutch Power Base Load Futures' },
    { label: 'AT', value: 'Austrian Power Financial Base Futures' },
    { label: 'BE', value: 'Belgian Power Base Load Futures' },
    { label: 'FR', value: 'French Power Financial Base Futures' },
    { label: 'DE', value: 'German Power Financial Base Futures' },
    { label: 'IT', value: 'Italian Power Financial Base Futures' },
    { label: 'ES', value: 'Spanish Power Financial Base Futures' },
    { label: 'CH', value: 'Swiss Power Financial Base Futures' }
  ],
  'ICE Power Peak': [
    { label: 'NL', value: 'Dutch Power Peak Load (8-20) Futures' },
    { label: 'FR', value: 'French Power Financial Peak Futures' },
    { label: 'DE', value: 'German Power Financial Peak Futures' },
    { label: 'IT', value: 'Italian Power Financial Peak Futures' },
    { label: 'BE', value: 'Belgian Power Financial Peak Futures' },
    { label: 'AT', value: 'Austrian Power Financial Peak Futures' }
  ]
}
