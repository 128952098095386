<template>
  <highcharts class="chart" :options="highChartOptions" ref="chartRef"></highcharts>
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'
import filters from '@/utils/filters'
import { useI18n } from 'vue-i18n'
import Repository from '@/repositories/RepositoryFactory'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'PorModelOccupancyChart',
  emits: ['syncDimensions', 'hideCard'],
  components: {},
  props: {
    title: {
      type: String,
      default: 'Generic Chart Title'
    },
    countryCode: {
      type: String,
      default: 'NL'
    },
    dateSelection: {
      type: String,
      default: '1h'
    },
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    },
    syncId: {
      type: String,
      default: null
    },
    syncWhat: {
      type: Array,
      default: () => []
    },
    xAxisLabel: {
      type: String,
      default: 'chart_labels.date'
    },
    yAxisLabel: {
      type: String,
      default: 'chart_labels.euro_per_mwh'
    },
    form: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Array,
      default: () => []
    },
    showType: {
      type: String,
      default: 'current'
    }
  },
  setup (props, context) {
    const StatisticsRepository = Repository.get('statistics')
    const isLoading = ref(false)
    const store = useStore()
    const { t } = useI18n()
    const uuid = uuidv4()
    const dynamicTitle = ref('')
    const kwMax = parseInt(props.form.variable_kw_max) * 1.0

    if (props.showType === 'current') {
      dynamicTitle.value = t('chart_labels.cumulative_current')
    } else {
      dynamicTitle.value = t('chart_labels.count')
    }

    const highChart = reactive({
      series: []
    })

    const chartRef = ref(null)

    // Watch for changes in syncedCharts
    const syncedCharts = computed(() => store.state.display.syncedCharts)
    if (props.syncWhat.length > 0 && props.syncId !== null) {
      watch(syncedCharts, () => {
        const chart = chartRef.value.chart
        // console.log('Calling syncDimensions')
        context.emit('syncDimensions', { uuid, chart, syncWhat: props.syncWhat, syncId: props.syncId })
      }, { deep: true })
    }

    // Sync after render
    const syncChart = (syncDimensions) => {
      if (props.syncWhat.length === 0 || props.syncId === null) {
        return
      }

      store.dispatch('display/syncChart', { uuid, syncId: props.syncId, syncWhat: props.syncWhat, syncDimensions })
    }

    const highChartOptions = computed({
      get () {
        return {
          chart: {
            styledMode: true,
            type: 'line',
            zoomType: 'xy',
            events: {
              redraw: function () {
                const chart = this
                const extremesY = chart.yAxis[0].getExtremes()
                const extremesX = chart.xAxis[0].getExtremes()

                syncChart({ height: chart.chartHeight, width: chart.chartWidth, xMin: extremesX.min, xMax: extremesX.max, yMin: extremesY.min, yMax: extremesY.max })

                const dimensions = filters.watermarkDimensions({
                  chartWidth: chart.chartWidth,
                  chartHeight: chart.chartHeight
                })

                if (chart.watermark) {
                  chart.watermark.attr({ x: dimensions.svgPosX, y: dimensions.svgPosY })
                } else if (extremesX.max !== undefined && extremesX.max !== null) {
                  chart.watermark = chart.renderer.image('/images/profiteia_logo_icon.svg', dimensions.svgPosX, dimensions.svgPosY, dimensions.svgWidth, dimensions.svgHeight).add()
                }
              }
            }
          },
          credits: {
            enabled: false,
            href: 'https://profiteia.io',
            text: 'profiteia.io'
          },
          title: {
            // text: t(props.title),
            text: undefined
          },
          xAxis: {
            type: 'datetime',
            title: {
              text: t(props.xAxisLabel)
            }
          },
          yAxis: [
            {
              title: {
                text: 'Vermogen'
              },
              plotLines: [{
                className: 'highcharts-color-red',
                value: props.form.variable_kw_max
              }]
            },
            {
              title: {
                text: dynamicTitle.value
              },
              opposite: true
            }
          ],
          tooltip: {
            pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
          },
          series: highChart.series,
          plotOptions: {
            column: {
              minPointLength: 3
            },
            series: {
              color: '#FF0000',
              marker: {
                enabled: false
              }
            }
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              }
            }]
          }
        }
      }
    })

    const getModel = () => {
      let totalCurrent = 0
      isLoading.value = true

      const dict = {}
      dict.current = {
        name: t('chart_labels.current'),
        type: 'line',
        data: []
      }

      dict.dynamic = {
        name: dynamicTitle.value,
        color: '#FF0000',
        yAxis: 1,
        className: 'highcharts-color-10',
        data: []
      }

      for (const [key, value] of Object.entries(props.model)) {
        const date = value.Datum
        const year = date.slice(0, 4)
        const month = parseInt(date.slice(5, 7)) - 1
        const day = parseInt(date.slice(8, 10))
        const hour = parseInt(date.slice(11, 13))
        const minute = parseInt(date.slice(14, 16))
        // console.log(year, month, day, hour, minute)

        if (props.showType === 'count') {
          dict.dynamic.data.push([Date.UTC(year, month, day, hour, minute, 0), value.Aantal])
        } else {
          dict.dynamic.data.push([Date.UTC(year, month, day, hour, minute, 0), totalCurrent / 4])
        }

        dict.current.data.push([Date.UTC(year, month, day, hour, minute, 0), value.Vermogen])
        totalCurrent += value.Vermogen
      }

      if (Object.keys(dict).length === 0) {
        context.emit('hideCard')
      }
      // console.log(dict)
      highChart.series = Object.values(dict)
    }

    onMounted(() => {
      getModel()
    })

    const onRender = () => {
      console.log('Chart rendered')
    }

    // defineExpose({
    //   uuid
    // })

    return {
      chartRef,
      highChartOptions,
      onRender,
      kwMax,
      isLoading
    }
  }
}
</script>

<style type="text/css">
.highcharts-plot-line {
  color: red;
  height: 2em;
  width: 2em;
}
</style>
