// src/utils/constants/chartConstants.js
export const dateTimeLabelFormats = {
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: '%H:%M',
  hour: '%H:%M',
  day: '%e. %b',
  week: '%e. %b',
  month: '%b \'%y',
  year: '%Y'
}

// Configure the options for the chart
export const credits = {
  enabled: false,
  href: 'https://profiteia.io',
  text: 'profiteia.io'
}

export const possibleTimeIntervals = [
  { 
    id: 0, 
    label: '1m',
    date_selection: 'minute',
    visible: true
  }, 
  { 
    id: 1, 
    label: '1h', 
    date_selection: 'hour',
    visible: true
  }, 
  { 
    id: 2, 
    label: '1D', 
    date_selection: 'day',
    visible: true
  }, 
  { 
    id: 3, 
    label: '1W', 
    date_selection: 'week',
    visible: true
  }, 
  { 
    id: 4,
    label: '1M', 
    date_selection: 'month',
    visible: true 
  }, 
  { 
    id: 5, 
    label: '1Y', 
    date_selection: 'year',
    visible: true
  }
]// .filter(interval => !disabledTimeIntervals.includes(interval.name))
