<template>
  <div ref="target">
    <card-component :icon="cardIcon" :title="$t(displayOptions.title)" :sourceHeader="`${$t('general_source')}: ${displayOptions.source}`" footer v-if="displayCard === true" :smallerPadding="true" :footerWithoutBorder="false">
      <div id="mapContainerWorld" class="chart"></div>
    </card-component>
  </div>
</template>

<script setup>
import filters from '@/utils/filters'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { useI18n } from 'vue-i18n'
import CardComponent from '@/components/CardComponent'
import countryList from '@/data-sources/multigasprices_countries.json'
import { ref, onMounted, computed, defineProps } from 'vue'
import Repository from '@/repositories/RepositoryFactory'
import {
  mdiFinance,
  mdiCog,
  mdiMoleculeCo2,
  mdiFire,
  mdiBarrel,
  mdiEarth,
  mdiPowerPlug,
  mdiGasCylinder,
  mdiShipWheel
} from '@mdi/js'

const props = defineProps({
  column: {
    type: Object
  }
})

// Options from column
const displayOptions = computed(() => props.column.displayOptions)
const { t } = useI18n()
const displayCard = ref(true)

const StatisticsRepository = Repository.get('statistics')
const map = ref(null)
const geojson = ref(null)

const getMultiGasPrices = async () => {
  const response = await StatisticsRepository.getMultiGasPrices({})
  const gasCountries = countryList
  const parsed = filters.parseMultiGasPrices({}, {}, response)

  // Go over gasCountries
  gasCountries.features.forEach(gasCountry => {
    const gasCountryHub = gasCountry.properties.gasHub

    parsed.series.forEach(gasHub => {
      if (gasCountryHub === gasHub.hub) {
        const latestGasPrice = gasHub.data[gasHub.data.length - 1][1].toFixed(2)
        gasCountry.properties.priceMwhEur = latestGasPrice
      }
    })
  })
  addValues(gasCountries)
  L.tileLayer('https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=cb6825c6c6b84b9daae37ac3954d740e', {
    attributionControl: false,
    attribution: ''
  }).addTo(map.value)
}

const style = (feature) => ({
  weight: 2,
  opacity: 1,
  color: 'white',
  dashArray: '3',
  fillOpacity: 0.7,
  fillColor: '#f1f1f1'
})

const highlightFeature = (e) => {
  const layer = e.target
  layer.setStyle({
    weight: 5,
    color: '#666',
    dashArray: '',
    fillOpacity: 0.7
  })
  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
    layer.bringToFront()
  }
}

const resetHighlight = (e) => geojson.value.resetStyle(e.target)

const addValues = (euCountries) => {
  geojson.value = L.geoJSON(euCountries, {
    style,
    onEachFeature
  }).addTo(map.value)

  map.value.fitBounds(geojson.value.getBounds())
  map.value.setView([46.05, 8.05], 2)
}

const onEachFeature = (feature, layer) => {
  let labelCoordinates = layer.getBounds().getCenter()

  if (feature.properties.labelCoordinates) {
    labelCoordinates = L.latLng(feature.properties.labelCoordinates.lat, feature.properties.labelCoordinates.lng)
  }

  if (feature.properties.priceMwhEur) {
    const label = L.marker(
      labelCoordinates,
      {
        icon: L.divIcon({
          html: '',
          iconSize: [0, 0]
        })
      }
    ).addTo(map.value)

    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight
    })

    label.bindTooltip(
      `${feature.properties.priceMwhEur}`,
      {
        permanent: true,
        direction: 'center',
        className: 'countryLabel'
      }
    )
  }
}

onMounted(() => {
  map.value = L.map('mapContainerWorld', { attributionControl: false, zoomSnap: 0.1 })
  getMultiGasPrices()
})

const cardIcon = computed(() => {
  const icons = {
    mdiMoleculeCo2,
    mdiFire,
    mdiBarrel,
    mdiEarth,
    mdiPowerPlug,
    mdiGasCylinder,
    mdiShipWheel
  }
  return icons[displayOptions.value.icon] || mdiFinance
})
</script>

<style scoped>
#mapContainerWorld {
  z-index: 50;
}
</style>
