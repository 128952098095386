<template>
  <component
    :is="isComponent"
    :class="[componentClass, componentBorderClass, darkBg]"
    @submit="handleSubmit"
  >
    <header
      v-if="hasHeaderSlot"
      :class="[headerBorder, headerFooterBorder, collapsible ? collapsibleHeaderClass : '', headerPadding]"
      @click.prevent="handleHeaderClick"
    >
      <slot name="header" :collapsed="isCollapsed" :icon="computedHeaderIcon" />
    </header>
    <header
      v-else-if="title"
      class="flex items-stretch"
      :class="[headerBorder, headerFooterBorder, collapsible ? 'cursor-pointer' : '']"
      @click.prevent="handleHeaderClick"
    >
      <p class="text-sm dark:text-emerald-500 flex items-center py-3 flex-grow font-normal" :class="titlePadding">
        <icon v-if="icon" :path="icon" class="mr-3" />
        {{ title }}
        <pill v-if="isPro" type="success" text="Pro" class="ml-2" small />
      </p>
      <a
        v-if="computedHeaderIcon"
        class="flex items-center py-3 px-4 justify-center cursor-pointer"
        @click.prevent="handleHeaderIconClick"
      >
        <icon :path="computedHeaderIcon" />
      </a>
      <a
        v-else-if="sourceHeader"
        :href="sourceHeaderUrl"
        target="_blank"
        class="flex items-center py-3 px-4 justify-center text-xs"
      >
        {{ sourceHeader }}
      </a>
    </header>
    <div v-if="empty" class="text-center py-24 lg:py-12 text-gray-500 dark:text-gray-400">
      <div class="mb-6">No items to show&hellip;</div>
      <div>
        <jb-button :icon="mdiPlusCircle" label="Add first item" :outline="darkMode" />
      </div>
    </div>
    <div
      v-else
      :class="[isCollapsed ? collapsedClass : '', contentPadding, { 'flex-1': flex || flexRow || hasFooter }]"
    >
      <slot />
    </div>
    <div v-if="hasFooter" :class="[footerWithoutBorder ? '' : headerFooterBorder, isCollapsed ? collapsedClass : '']" class="p-2 px-4 border-t flex justify-between flex-wrap">
      <slot name="footer" />
    </div>
  </component>
</template>

<script setup>
import { defineProps, defineEmits, useSlots, computed, ref } from 'vue'
import { mdiPlusCircle, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { useStore } from 'vuex'
import Icon from '@/components/Icon'
import JbButton from '@/components/JbButton'
import Pill from '@/components/Pill'

// Props
const props = defineProps({
  darkBg: {
    type: String,
    default: 'dark:bg-gray-900 dark:border-gray-900'
  },
  title: String,
  icon: String,
  headerIcon: String,
  hasTable: Boolean,
  empty: Boolean,
  smallerPadding: Boolean,
  cardWithoutBorder: Boolean,
  headerWithoutBorder: Boolean,
  footerWithoutBorder: Boolean,
  flex: Boolean,
  flexRow: Boolean,
  form: Boolean,
  hoverable: Boolean,
  collapsible: Boolean,
  isPro: Boolean,
  sourceHeader: String,
  sourceHeaderUrl: String,
  collapsibleHeaderClass: {
    type: String,
    default: 'cursor-pointer'
  },
  collapsedClass: {
    type: String,
    default: 'hidden'
  },
  rounded: {
    type: String,
    default: 'md:rounded'
  }
})

// Emits
const emit = defineEmits(['header-icon-click', 'submit'])

// Vuex store
const store = useStore()
const darkMode = computed(() => store.state.display.darkMode)

// Reactive state
const collapsed = ref(true)
const isCollapsed = computed(() => props.collapsible && collapsed.value)

// Computed properties
const hasHeaderSlot = computed(() => !!useSlots().header)
const hasFooter = computed(() => !!useSlots().footer)

const headerPadding = computed(() => (!props.hasTable && !props.smallerPadding ? 'p-6' : 'py-6 px-4'))
const titlePadding = computed(() => (props.smallerPadding || props.icon ? 'px-4' : 'px-6'))
const contentPadding = computed(() => (!props.hasTable && !props.smallerPadding ? 'p-6' : 'py-6 px-4'))

const headerBorder = computed(() => {
  if (props.headerWithoutBorder) return ''
  return 'border-b'
})

const headerFooterBorder = 'border-gray-100 dark:border-gray-700'

const isComponent = computed(() => (props.form ? 'form' : 'div'))

const componentBorderClass = computed(() => {
  const base = 'bg-white border border-gray-100'
  if (props.cardWithoutBorder) return 'bg-white'
  return base
})

const componentClass = computed(() => {
  const base = [props.rounded]
  if (props.flexRow) base.push('flex flex-row')
  else if (props.flex || hasFooter.value) base.push('flex flex-col')
  else base.push('block')
  if (props.hoverable) base.push('hover:shadow-lg transition-shadow duration-500')
  return base
})

const computedHeaderIcon = computed(() => {
  if (props.headerIcon) return props.headerIcon
  if (props.collapsible) return collapsed.value ? mdiChevronDown : mdiChevronUp
  return null
})

// Event handlers
const handleHeaderClick = () => {
  if (props.collapsible) {
    collapsed.value = !collapsed.value
  }
}

const handleHeaderIconClick = (e) => {
  emit('header-icon-click', e)
}

const handleSubmit = (e) => {
  emit('submit', e)
}
</script>
