import i18n from '@/i18n'

export default {
  isPlainObject (value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Function)
  },
  watermarkDimensions (dimensions) {
    dimensions.svgPosX = 0
    dimensions.svgPosY = 0
    dimensions.svgWidth = 137
    dimensions.svgHeight = 16.79

    // const heightMultiplier = 0.55
    // let widthMultiplier = 3.0
    const xMargin = 10
    const yMargin = 50

    // // Height should always be the same, use different proportions for sizes
    // if (dimensions.chartWidth > 800) {
    //   widthMultiplier = 3.4
    //   xMargin = 0.02
    //   yMargin = 0.08
    // } else if (dimensions.chartWidth > 400) {
    //   widthMultiplier = 2.6
    //   xMargin = 0.02
    //   yMargin = 0.10
    // } else {
    //   widthMultiplier = 2.2
    //   xMargin = 0.06
    //   yMargin = 0.05
    // }
    // text

    dimensions.svgPosX = 0 + xMargin
    dimensions.svgPosY = dimensions.yAxisHeight - dimensions.svgHeight
    dimensions.svgMaxY = (dimensions.chartHeight) - (dimensions.svgHeight)
    // console.log(dimensions)
    return dimensions
  },
  splitAndTranslate (str) {
    let month = str.slice(0, 3).toLowerCase()
    const year = str.slice(3)

    month = i18n.global.t(month)
    return `${month}${year}`
  },
  parseICESettlementsData (payload, displayOptions, response) {
    const lastUpdated = response.data.date
    const translatedObj = Object.fromEntries(
      Object.entries(response.data.results).map(([key, value]) => [this.splitAndTranslate(key), value])
    )
    const categories = Object.keys(translatedObj)
    const series = [{
      name: lastUpdated,
      data: Object.values(translatedObj),
      tooltip: {
        headerFormat: '<b>{point.key}</b> - {series.name}<br/>',
        pointFormat: `{point.y:.2f} ${i18n.global.t(displayOptions.yAxisLabel)}`
      }
    }]

    return { lastUpdated, categories, series }
  },
  parseSensorData (payload, displayOptions, response) {
    const list = []
    const listBase = []
    for (const [key, value] of Object.entries(response.data)) {
      const itemDate = parseInt(value[0])
      list.push([itemDate, value[1]])
      listBase.push([itemDate, 50])
    }

    const series = [
      {
        name: i18n.global.t('chart_labels.frequency_hz'),
        data: list,
        tooltip: {
          headerFormat: '<b>{point.x:%Y-%m-%d %H:%M}</b><br/>',
          pointFormat: `{point.y:.3f} ${i18n.global.t('chart_labels.hertz')}`
        }
      },
      {
        name: i18n.global.t('chart_labels.frequency_base_hz'),
        data: listBase,
        tooltip: {
          headerFormat: '<b>{point.x:%Y-%m-%d %H:%M}</b><br/>',
          pointFormat: `{point.y:.3f} ${i18n.global.t('chart_labels.hertz')}`
        }
      }
    ]

    return { series }
  },
  parseICELiveData (payload, displayOptions, response) {
    // Use UTC to determine if the TTF is open
    const currentHour = new Date().getUTCHours()
    const currentDay = new Date().getUTCDay()
    const list = []

    // console.log(response.data.data)
    response.data.data.forEach(item => {
      const itemDate = parseInt(item[0])
      const itemDateObject = new Date(itemDate)

      if ((currentDay === 0 || currentDay === 6) && itemDateObject.getUTCDay() === 5) {
        // If today is a saturdau or sunday and we have data from friday, pass
      } else if (currentHour > 6 && itemDateObject.getUTCDay() !== currentDay) {
        // If the current hour is a new trading day
        return
      }

      if (list.at(-1) !== undefined && (list.at(-1)[0] - itemDate) !== -300000) {
        let lastTime = list.at(-1)[0]

        while (lastTime < itemDate) {
          lastTime += 300000
          list.push([lastTime, null])
        }
      }

      list.push([itemDate, item[4]])
    })

    const series = {
      name: 'TTF Natural Gas',
      data: list,
      tooltip: {
        headerFormat: '<b>{point.x:%Y-%m-%d %H:%M}</b><br/>',
        pointFormat: `{point.y:.2f} ${i18n.global.t('chart_labels.euro_per_mwh')}`
      }
    }

    return { series }
  },
  parseICEData (payload, displayOptions, response) {
    const returnObject = {}

    for (const [index, indexData] of Object.entries(response.data)) {
      const ts = Date.parse(index)

      for (const [strip, oValue] of Object.entries(indexData)) {
        // console.log(strip)
        if (!('strips' in payload) || payload.strips.includes(strip)) {
          if (returnObject[strip] === undefined) {
            returnObject[strip] = {
              name: `${strip}`,
              strip: `${strip}`,
              data: [],
              tooltip: {
                headerFormat: '<b>{series.userOptions.strip}</b> - {point.x:%Y-%m-%d} <br/>',
                pointFormat: `{point.y:.2f} ${i18n.global.t(displayOptions.yAxisLabel)}`
              }
            }
            // yAxisLabel
            // Add index if numeric index (like years)
            if (strip === 'FM') {
              returnObject[strip].name = `${i18n.global.t('chart_labels.front_month')}`
            }

            if (strip === 'DA') {
              returnObject[strip].name = `${i18n.global.t('chart_labels.day_ahead')}`
            }

            if (strip.startsWith('Cal') || strip.startsWith('Dec')) {
              returnObject[strip].legendIndex = parseInt(strip.replace('Cal ', '').replace('Dec', ''))
            }
          }
          returnObject[strip].data.push([ts, oValue])
        }
      }
    }

    const renamedReturnedObject = {}

    for (const key in returnObject) {
      if (Object.prototype.hasOwnProperty.call(returnObject, key)) {
        if (Array.isArray(returnObject[key].data) && returnObject[key].data.length > 0) {
          const data = returnObject[key].data.sort((a, b) => a[0] - b[0])

          const lastValue = this.roundToDecimal(data[data.length - 1][1], 2) // Round to 2 decimal places
          renamedReturnedObject[key] = returnObject[key]
          renamedReturnedObject[key].name = `${returnObject[key].name} (${lastValue})`
          renamedReturnedObject[key].data = data
        }
      }
    }
    return Object.values(renamedReturnedObject)
  },
  parseMultiGasPrices (payload, displayOptions, response) {
    const dict = {}

    for (const [key, value] of Object.entries(response.data)) {
      const ts = Date.parse(key)

      // console.log('multigas', ts, key, value)
      for (const [hubIndex, oValue] of Object.entries(value)) {
        // console.log(hubIndex, oValue.price_eur_mwh)
        let hub = ''
        let className = 'highcharts-color-0'

        if (hubIndex === 'dutch_ttf') {
          hub = 'Dutch TTF'
        } else if (hubIndex === 'lng_jkm') {
          hub = 'LNG Japan/Korea Marker'
          className = 'highcharts-color-red'
        } else if (hubIndex === 'henry_hub') {
          hub = 'Henry Hub'
          className = 'highcharts-color-green'
        } else if (hubIndex === 'mcx_spot') {
          hub = 'MCX Spot'
          className = 'highcharts-color-blue'
        } else if (hubIndex === 'spimex') {
          hub = 'SPIMEX'
          className = 'highcharts-color-yellow'
        } else if (hubIndex === 'alberta_gas') {
          hub = 'Alberta Gas'
          className = 'highcharts-color-purple'
        } else if (hubIndex === 'aemo_gsh') {
          hub = 'AEMO GSH'
          className = 'highcharts-color-orange'
        } else {
          console.log('Unknown hub', hubIndex)
        }

        if (hub !== '') {
          if (dict[hubIndex] === undefined) {
            dict[hubIndex] = {
              name: `${hub}`,
              hub: `${hubIndex}`,
              data: [],
              className,
              tooltip: {
                headerFormat: '<b>{series.name}</b> - {point.x:%Y-%m-%d}<br/>',
                pointFormat: `{point.y:.2f} ${i18n.global.t('chart_labels.euro_per_mwh')}`
              }
            }
          }
          dict[hubIndex].data.push([ts, oValue.price_eur_mwh])
        }
      }
    }

    // Sort by ts
    for (const [key, val] of Object.entries(dict)) {
      dict[key].data = val.data.sort(function (a, b) { return a[0] - b[0] })
    }

    const series = Object.values(dict)
    return { series }
  },
  parseTennetImbalanceRelativeChange (payload, displayOptions, response) {
    const dict = {}

    // console.log('a')
    let headerFormat = '<b>{series.name}</b> - {point.x:%Y-%m-%d %H:%M}<br/>'

    if (payload.date_selection === 'day' || payload.date_selection === 'month' || payload.date_selection === 'week' || payload.date_selection === 'year') {
      headerFormat = '<b>{series.name}</b> - {point.x:%Y-%m-%d} <br/>'
    }
    // console.log('b')

    payload.fields.forEach(field => {
      dict[field.display_label] = {
        name: `${i18n.global.t('chart_labels.' + field.display_label)}`,
        data: [],
        tooltip: {
          headerFormat,
          pointFormat: '{point.y:.2f}'
        }
      }

      if ('stack' in field) {
        dict[field.display_label].stack = field.stack
      }

      if ('point_format_unit' in field) { // Allow override by field
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(field.point_format_unit)
      } else if ('pointFormatUnit' in displayOptions) { // Allow override by column
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(displayOptions.pointFormatUnit)
      } else {
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(displayOptions.yAxisLabel)
      }
    })

    // console.log('d')

    if (response.data.length === 0) {
      return dict
    }
    // console.log('d1')
    response.data.forEach(point => {
      const date = new Date(point.key)

      payload.fields.forEach(field => {
        // console.log(field, point)

        if (field.display_label in point) {
          let value = point[field.display_label]

          if ('transform' in field && 'fn' in field.transform) {
            if (field.transform.fn === 'mul') {
              value = value * field.transform.fn_param
            }
            if (field.transform.fn === 'div') {
              value = value / field.transform.fn_param
            }
          }
          if ('transform' in field && 'removeZeroes' in field.transform) {
            if (value === 0) {
              value = null
            }
            // console.log(value)
          }
          // dict[field.display_label].data.push([Date.UTC(year, month, day, hour, minute, 0), value])
          dict[field.display_label].data.push([date.getTime(), value])
        }
      })
    })
    // remove 0-sized fields
    const series = Object.values(dict)
    return { series }
  },
  parseTennetImbalancePricesEod (payload, displayOptions, response) {
    const dict = {}

    // console.log('a')
    let headerFormat = '<b>{series.name}</b> - {point.x:%Y-%m-%d %H:%M}<br/>'

    if (payload.date_selection === 'day' || payload.date_selection === 'month' || payload.date_selection === 'week' || payload.date_selection === 'year') {
      headerFormat = '<b>{series.name}</b> - {point.x:%Y-%m-%d} <br/>'
    }
    // console.log('b')

    payload.fields.forEach(field => {
      dict[field.display_label] = {
        name: `${i18n.global.t('chart_labels.' + field.display_label)}`,
        data: [],
        tooltip: {
          headerFormat,
          pointFormat: '{point.y:.2f}'
        }
      }

      if ('stack' in field) {
        dict[field.display_label].stack = field.stack
      }

      if ('point_format_unit' in field) { // Allow override by field
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(field.point_format_unit)
      } else if ('pointFormatUnit' in displayOptions) { // Allow override by column
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(displayOptions.pointFormatUnit)
      } else {
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(displayOptions.yAxisLabel)
      }
    })

    // console.log('d')

    if (response.data.length === 0) {
      return dict
    }
    // console.log('d1')
    response.data.forEach(point => {
      const date = new Date(point.key)

      payload.fields.forEach(field => {
        // console.log(field, point)

        if (field.display_label in point) {
          let value = point[field.display_label]

          if ('transform' in field && 'fn' in field.transform) {
            if (field.transform.fn === 'mul') {
              value = value * field.transform.fn_param
            }
            if (field.transform.fn === 'div') {
              value = value / field.transform.fn_param
            }
          }
          if ('transform' in field && 'removeZeroes' in field.transform) {
            if (value === 0) {
              value = null
            }
            // console.log(value)
          }
          // dict[field.display_label].data.push([Date.UTC(year, month, day, hour, minute, 0), value])
          dict[field.display_label].data.push([date.getTime(), value])
        }
      })
    })
    // remove 0-sized fields
    const series = Object.values(dict)
    return { series }
  },
  parseTenneTImbalanceControlStates (payload, displayOptions, response) {
    const datesNegativeOne = []
    const datesZero = []
    const datesOne = []
    const datesTwo = []

    const series = [
      {
        name: '2',
        data: []
      },
      {
        name: '1',
        data: []
      },
      {
        name: '0',
        data: []
      },
      {
        name: '-1',
        data: []
      }
    ]

    for (const [key, values] of Object.entries(response.data)) {
      const date = parseInt(key)
      // console.log(date)
      datesNegativeOne.push([date, values['-1']])
      datesZero.push([date, values['0']])
      datesOne.push([date, values['1']])
      datesTwo.push([date, values['2']])
    }

    series[3].data = datesNegativeOne
    series[2].data = datesZero
    series[1].data = datesOne
    series[0].data = datesTwo

    // console.log(chartOptions.value)
    // const categories = categoryDates.sort()
    return { series }
  },
  parseTennetSettlementPricesHeatmap (payload, displayOptions, response) {
    const categoryDates = []
    const heatmapData = []
    const dict = {}

    dict.heatmap = {
      name: '€/MWh',
      turboThreshold: 0,
      borderWidth: 1,
      data: [],
      dataLabels: {
        enabled: false,
        color: '#000000'
      }
    }

    let count = 0

    for (const [key, values] of Object.entries(response.data)) {
      const date = key.slice(0, 10)
      categoryDates.push(date)

      // console.log(key, values)

      for (const [k, v] of Object.entries(values)) {
        const index = parseInt(k) - 1
        const val = Math.round(v)

        if (index < 96) {
          heatmapData.push([index, count, val])
        }
      }

      count += 1
    }

    dict.heatmap.data = heatmapData

    // console.log(chartOptions.value)
    const categories = categoryDates.sort()
    const yAxisCategories = categoryDates.sort()
    const series = Object.values(dict)
    return { categories, yAxisCategories, series }
  },
  parseENTSOEDayAheadMovingAverageForCountry (payload, displayOptions, response) {
    const dict = {}
    dict.hours = {
      name: i18n.global.t('chart_labels.hourly_price'),
      data: [],
      tooltip: {
        headerFormat: '<b>{series.name}</b><br/>',
        pointFormat: `{point.x:%Y-%m-%d %H:%M}: {point.y:.2f} ${i18n.global.t('chart_labels.euro_per_mwh')}`
      }
    }

    dict.movingAverage = {
      name: 'Moving Average',
      color: '#FF0000',
      className: 'highcharts-color-red',
      data: [],
      tooltip: {
        headerFormat: '<b>{series.name}</b><br/>',
        pointFormat: `{point.x:%Y-%m-%d %H:%M}: {point.y:.2f} ${i18n.global.t('chart_labels.euro_per_mwh')}`
      }
    }

    for (const [key, value] of Object.entries(response.data.hour_consecutive)) {
      const date = new Date(key)
      dict.hours.data.push([date.getTime(), value])
      // const year = key.slice(0, 4)
      // const month = parseInt(key.slice(5, 7)) - 1
      // const day = parseInt(key.slice(8, 10))
      // const hour = parseInt(key.slice(11, 13))
      // dict.hours.data.push([Date.UTC(year, month, day, hour, 0, 0), value])
    }

    for (const [key, value] of Object.entries(response.data.moving_average)) {
      const date = new Date(key)
      dict.movingAverage.data.push([date.getTime(), value])
      // const year = key.slice(0, 4)
      // const month = parseInt(key.slice(5, 7)) - 1
      // const day = parseInt(key.slice(8, 10))
      // const hour = parseInt(key.slice(11, 13))
      // dict.movingAverage.data.push([Date.UTC(year, month, day, hour, 0, 0), value])
    }
    // console.log(dict)
    const series = Object.values(dict)
    return { series }
  },
  parseTennetSettlementPricesMovingAverage (payload, displayOptions, response) {
    const dict = {}

    dict.movingAverageAf = {
      name: i18n.global.t('chart_labels.ma_take_from_system'),
      color: '#FF0000',
      className: 'highcharts-color-0',
      data: [],
      tooltip: {
        headerFormat: '<b>{series.name}</b><br/>',
        pointFormat: `{point.x:%Y-%m-%d %H:%M}: {point.y:.2f} ${i18n.global.t('chart_labels.euro_per_mwh')}`
      }
    }

    dict.movingAverageIn = {
      name: i18n.global.t('chart_labels.ma_feed_into_system'),
      color: '#FF0000',
      className: 'highcharts-color-2',
      data: [],
      tooltip: {
        headerFormat: '<b>{series.name}</b><br/>',
        pointFormat: `{point.x:%Y-%m-%d %H:%M}: {point.y:.2f} ${i18n.global.t('chart_labels.euro_per_mwh')}`
      }
    }

    for (const [key, value] of Object.entries(response.data.moving_average_afnemen)) {
      const date = new Date(key)
      dict.movingAverageAf.data.push([date.getTime(), value])
      // const year = key.slice(0, 4)
      // const month = parseInt(key.slice(5, 7)) - 1
      // const day = parseInt(key.slice(8, 10))
      // const hour = parseInt(key.slice(11, 13))
      // dict.movingAverageAf.data.push([Date.UTC(year, month, day, hour, 0, 0), value])
    }

    for (const [key, value] of Object.entries(response.data.moving_average_invoeden)) {
      const date = new Date(key)
      dict.movingAverageIn.data.push([date.getTime(), value])
      // const year = key.slice(0, 4)
      // const month = parseInt(key.slice(5, 7)) - 1
      // const day = parseInt(key.slice(8, 10))
      // const hour = parseInt(key.slice(11, 13))
      // dict.movingAverageIn.data.push([Date.UTC(year, month, day, hour, 0, 0), value])
    }

    const series = Object.values(dict)
    return { series }
  },
  parseENTSOGConsumption (payload, displayOptions, response) {
    const dict = {}
    const categories = []
    const currentYear = new Date().getFullYear()

    // highChartOptions.series = []
    dict.monthPrice = {
      name: i18n.global.t('chart_labels.month'),
      type: 'column',
      data: [],
      tooltip: {
        headerFormat: '<b>{point.x:%Y-%m}</b><br/>',
        pointFormat: `{point.y:.2f} ${i18n.global.t('chart_labels.twh_per_month')}`
      }
    }

    dict.yearPrice = {
      name: i18n.global.t('chart_labels.year'),
      color: '#FF0000',
      yAxis: 1,
      className: 'highcharts-color-red',
      data: [],
      tooltip: {
        headerFormat: '<b>{point.x:%Y}</b><br/>',
        pointFormat: `{point.y:.2f} ${i18n.global.t('chart_labels.twh_per_year')}`
      }
    }

    for (const [key, value] of Object.entries(response.data.months)) {
      dict.monthPrice.data.push(value.value / 1000 / 1000)
      categories.push(value.key_as_string.slice(0, 7))
    }

    for (const [key, value] of Object.entries(response.data.years)) {
      if (value.key_as_string.startsWith(currentYear)) {
        const currentMonth = new Date().getMonth() + 1
        for (let i = 0; i < currentMonth; i++) {
          dict.yearPrice.data.push(value.value / 1000 / 1000)
        }
      } else {
        for (let i = 0; i < 12; i++) {
          dict.yearPrice.data.push(value.value / 1000 / 1000)
        }
      }
    }

    const series = Object.values(dict)
    return { categories, series }
  },
  parseGIE (payload, displayOptions, response) {
    const dict = {}

    response.data.forEach(item => {
      const year = item.key_as_string.slice(0, 4)
      const month = parseInt(item.key_as_string.slice(5, 7)) - 1
      const day = parseInt(item.key_as_string.slice(8, 10))

      if (parseInt(year) > 2017) {
        if (dict[year] === undefined) {
          dict[year] = {
            name: `${i18n.global.t(payload.fields[0].display_label)} ${year}`,
            data: [],
            tooltip: {
              headerFormat: '<b>{series.name}</b> - {point.x:%b-%d}<br/>',
              pointFormat: '{point.y:.2f} TWh'
            }
          }
        }

        dict[year].data.push([Date.UTC(1970, month, day), item[payload.fields[0].field_value].value])
      }
    })

    const series = Object.values(dict)
    // console.log(series)
    return { series }
  },
  simpleParseFetch (payload, displayOptions, response) {
    const dict = {}
    let headerFormat = '<b>{series.name}</b> - {point.x:%Y-%m-%d %H:%M}<br/>'

    if (payload.date_selection === 'day' || payload.date_selection === 'month' || payload.date_selection === 'week' || payload.date_selection === 'year') {
      headerFormat = '<b>{series.name}</b> - {point.x:%Y-%m-%d} <br/>'
    }

    payload.fields.forEach(field => {
      dict[field.display_label] = {
        name: `${i18n.global.t('chart_labels.' + field.display_label)}`,
        data: [],
        tooltip: {
          headerFormat,
          pointFormat: '{point.y:.2f}'
        }
      }

      if ('stack' in field) {
        dict[field.display_label].stack = field.stack
      }

      if ('point_format_unit' in field) { // Allow override by field
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(field.point_format_unit)
      } else if ('pointFormatUnit' in displayOptions) { // Allow override by column
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(displayOptions.pointFormatUnit)
      } else {
        dict[field.display_label].tooltip.pointFormat = '{point.y:.2f} ' + i18n.global.t(displayOptions.yAxisLabel)
      }
    })

    if (response.data.length === 0) {
      return dict
    }

    response.data.forEach(point => {
      const date = new Date(point.key_as_string)

      payload.fields.forEach(field => {
        if (field.display_label in point) {
          let value = point[field.display_label].value

          if ('transform' in field && 'fn' in field.transform) {
            if (field.transform.fn === 'mul') {
              value = value * field.transform.fn_param
            }
            if (field.transform.fn === 'div') {
              value = value / field.transform.fn_param
            }
          }
          if ('transform' in field && 'removeZeroes' in field.transform) {
            if (value === 0) {
              value = null
            }
            // console.log(value)
          }
          // dict[field.display_label].data.push([Date.UTC(year, month, day, hour, minute, 0), value])
          dict[field.display_label].data.push([date.getTime(), value])
        }
      })
    })

    // Create an extra field and remove the original fields
    if ('createField' in payload) {
      // "createField": {
      //   "display_label": "settlement_price",
      //   "function": {
      //     "fn": "sub",
      //     "fn_param": ["opregelen", "afregelen"]
      //   }
      // }

      const data = []

      const fn = payload.createField.function.fn
      for (let i = 0; i < dict[payload.createField.function.fn_param[0]].data.length; i++) {
        const date1 = dict[payload.createField.function.fn_param[0]].data[i][0]
        const date2 = dict[payload.createField.function.fn_param[1]].data[i][0]

        const value1 = dict[payload.createField.function.fn_param[0]].data[i][1]
        const value2 = dict[payload.createField.function.fn_param[1]].data[i][1]

        if (date1 !== date2) {
          continue
        }

        if (fn === 'sub') {
          data.push([dict[payload.createField.function.fn_param[0]].data[i][0], value1 - value2])
        } else if (fn === 'add') {
          data.push([dict[payload.createField.function.fn_param[0]].data[i][0], value1 + value2])
        }
      }

      dict[payload.createField.display_label] = {
        name: `${i18n.global.t('chart_labels.' + payload.createField.display_label)}`,
        data: data,
        tooltip: {
          headerFormat,
          pointFormat: '{point.y:.2f}'
        }
      }

      // remove fn_param fields from dict
      for (const param of payload.createField.function.fn_param) {
        delete dict[param]
      }
    }

    // remove 0-sized fields
    return Object.fromEntries(
      Object.entries(dict).filter(([key, value]) => value.data.length !== 0)
    )
  },
  getObjectFromProxy (proxy) {
    const obj = {}
    for (const key of Reflect.ownKeys(proxy)) {
      obj[key] = proxy[key]
    }
    return obj
  },
  roundToDecimal (number, decimalPlaces) {
    const factor = 10 ** decimalPlaces
    return Math.round(number * factor) / factor
  },
  indicesToArray (indices) {
    const indicesArray = []

    if (indices === undefined) {
      return indicesArray
    }

    indices.forEach(function (element) {
      if (typeof element === 'object') {
        indicesArray.push(element.index)
      } else {
        indicesArray.push(element)
      }
    })

    return indicesArray
  },
  formatDate (timestamp) {
    return this.formatDateLocalized(timestamp)
    // const date = new Date(timestamp)
    // return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`
  },
  formatDateLocalized (timestamp) {
    const date = new Date(timestamp)

    // Format the date and time
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`

    // Get timezone offset in minutes and convert it to hours and minutes
    const timezoneOffset = date.getTimezoneOffset()
    const offsetHours = Math.abs(Math.floor(timezoneOffset / 60))
    const offsetMinutes = Math.abs(timezoneOffset % 60)

    // Format timezone offset as +/-HH:MM
    const timezoneFormatted = (timezoneOffset > 0 ? '-' : '+') + String(offsetHours).padStart(2, '0') + ':' + String(offsetMinutes).padStart(2, '0')

    return formattedDate + timezoneFormatted
  },
  processStrips (strips) {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear() - 2000

    if (strips.every(item => /^Cal [1-3][0-9]$|^Dec [1-3][0-9]$/.test(item))) {
      return strips
    }

    const newStrips = strips.map(strip => {
      const regex = /(Cal|Dec) (CY)?([-+]\d+)?/
      const match = strip.match(regex)
      if (match) {
        const prefix = match[1]
        const offset = parseInt(match[3], 10) || 0
        if (prefix === 'Dec') {
          return `${prefix}${currentYear + offset}`
        } else {
          return `${prefix} ${currentYear + offset}`
        }
      }
      return strip
    })

    return newStrips
  },
  renderDate (dateType, dateString) {
    const dateActions = {
      now: d => d,
      end_of_previous_year: d => {
        d.setFullYear(d.getFullYear() - 1, 11, 31)
        d.setHours(23, 59, 59, 999)
        return d
      },
      start_of_two_years_ago: d => {
        d.setFullYear(d.getFullYear() - 2, 0, 1)
        return d
      },
      start_of_three_years_ago: d => {
        d.setFullYear(d.getFullYear() - 3, 0, 1)
        return d
      },
      start_of_four_years_ago: d => {
        d.setFullYear(d.getFullYear() - 4, 0, 1)
        return d
      },
      start_of_five_years_ago: d => {
        d.setFullYear(d.getFullYear() - 5, 0, 1)
        return d
      },
      start_of_ten_years_ago: d => {
        d.setFullYear(d.getFullYear() - 10, 0, 1)
        return d
      },
      start_of_fifteen_years_ago: d => {
        d.setFullYear(d.getFullYear() - 15, 0, 1)
        return d
      },
      start_of_year: d => {
        d.setFullYear(d.getFullYear(), 0, 1)
        return d
      },
      start_of_last_year: d => {
        d.setFullYear(d.getFullYear() - 1, 0, 1)
        return d
      },
      five_days_ago: d => {
        d.setDate(d.getDate() - 5)
        return d
      },
      two_weeks_ago: d => {
        d.setDate(d.getDate() - 14)
        return d
      },
      seven_weeks_ago: d => {
        d.setDate(d.getDate() - 49)
        return d
      },
      ninety_days_ago: d => {
        d.setDate(d.getDate() - 90)
        return d
      },
      one_week_ago: d => {
        d.setDate(d.getDate() - 7)
        return d
      },
      one_year_ago: d => {
        d.setFullYear(d.getFullYear() - 1)
        return d
      },
      start_of_yesterday: d => {
        d.setDate(d.getDate() - 1)
        return d
      },
      start_of_today: d => d,
      start_of_tomorrow: d => {
        d.setDate(d.getDate() + 1)
        return d
      },
      twelve_hours_ago: d => {
        d.setHours(d.getHours() - 12)
        return d
      },
      ten_minutes_ago: d => {
        d.setMinutes(d.getMinutes() - 10)
        return d
      },
      two_minutes_ago: d => {
        d.setMinutes(d.getMinutes() - 2)
        return d
      },
      tomorrow: d => {
        d.setDate(d.getDate() + 1)
        d.setHours(0, 0, 0, 0)
        return d
      },
      end_of_previous_month: d => {
        d.setDate(0)
        d.setHours(23, 59, 59, 999)
        return d
      },
      end_of_yesterday: d => {
        d.setDate(d.getDate() - 1)
        return d
      },
      end_of_today: d => {
        return d
      },
      end_of_tomorrow: d => {
        d.setDate(d.getDate() + 1)
        return d
      },
      start_of_day_after_tomorrow: d => {
        d.setDate(d.getDate() + 2)
        d.setHours(0, 0, 0, 0)
        return d
      },
      twelve_hours_in_the_future: d => {
        d.setHours(d.getHours() + 12)
        return d
      }
    }

    const d = new Date()

    if (dateType === 'start_date') {
      if (dateString.includes('hours')) {
        d.setMinutes(0, 0, 0)
      } else if (dateString.includes('minutes')) {
        d.setSeconds(0, 0)
      } else {
        d.setHours(0, 0, 0, 0)
      }
    } else {
      if (dateString.includes('end_of')) {
        d.setHours(23, 59, 59, 999)
      } else if (dateString.includes('hour')) {
        d.setMinutes(59, 59, 999)
      } else if (dateString.includes('minutes')) {
        d.setMinutes(59, 59, 999)
      }
    }

    if (dateString in dateActions) {
      dateString = this.formatDate(dateActions[dateString](d))
    }

    return dateString
  },
  renderSchema (typeSchema) {
    typeSchema.forEach(row => {
      for (const [key, column] of Object.entries(row.columns)) {
        if ('start_date' in column.fetchOptions) {
          column.fetchOptions.start_date = this.renderDate('start_date', column.fetchOptions.start_date)
        }

        if ('end_date' in column.fetchOptions) {
          // console.log(column.fetchOptions.end_date)
          column.fetchOptions.end_date = this.renderDate('end_date', column.fetchOptions.end_date)
          // console.log(column.fetchOptions.end_date)
        }

        if ('strips' in column.fetchOptions) {
          column.fetchOptions.strips = this.processStrips(column.fetchOptions.strips)
        }
      }
    })

    return typeSchema
  }
}
