<template>
  <!-- component -->
  <section v-show="isLatestNewsBarVisible" class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto max-w-7x1">
      <div class="flex flex-wrap w-full mb-4 p-4">
        <div class="w-full mb-6 lg:mb-0">
          <h1 class="sm:text-4xl text-5xl font-medium font-bold title-font mb-2 text-gray-900">{{ t('card_titles.latest_news') }}</h1>
          <div class="h-1 w-20 bg-emerald-500 rounded"></div>
        </div>
      </div>
      <div class="flex flex-wrap -m-4">
        <div class="xl:w-1/3 md:w-1/2 p-4" v-for="item in itemsPaginated" :key="item.timestamp">
          <div class="bg-white p-6 rounded-lg">
            <h3 class="tracking-widest text-emerald-500 text-xs font-medium title-font">
              {{ formatTimestamp(item.timestamp) }}
              <pill type="light" :text="$t('sources.' + item.source)" outline small/>
            </h3>
            <text-link class="leading-relaxed text-base" :href="item.link" :label="item.title" target="_blank" :underline="false"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Level from '@/components/Level'
import JbButton from '@/components/JbButton'
import Control from '@/components/Control'
import Field from '@/components/Field'
import Pill from '@/components/Pill'
import Repository from '@/repositories/RepositoryFactory'
import { useI18n } from 'vue-i18n'
import { formatTimestamp } from '@/utils/helpers'
import TextLink from '@/components/TextLink'

const store = useStore()
const isLatestNewsBarVisible = computed(() => !store.state.display.isFormScreen)
const { t } = useI18n()
const StatisticsRepository = Repository.get('statistics')
const items = ref([])
const itemsPaginated = computed(() => items.value.slice(0, 9))

const retrieveNews = async () => {
  const response = await StatisticsRepository.getNews()
  items.value = response.data
}

onMounted(() => {
  retrieveNews()
})

</script>
