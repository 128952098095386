<template>
  <form-screen-section bg="gas" promo>
    <div v-if="$i18n.locale === 'nl'" class="bg-white lg:w-2/3 p-8 lg:rounded-xl text-base leading-9 pb-24 md:m-5 mt-10">
        <p>
          Het idee voor een energiedataplatform als Profiteia is in 2003 ontstaan toen de energiemarkt net geliberaliseerd was. Jan Willem Zwang zat toen koud twee jaar in de energiemarkt en had al snel in de gaten dat kwalitatief goede data onontbeerlijk is voor deelname aan de energiemarkt. Het tijdig beschikken over kwalitatief goede en relevante data is essentieel bij het ontwikkelen van visie, het opstellen van strategie en het uitvoeren van energiebeleid bij het inzetten van energieproductie, energieverbruik en energieopslag.
          De afgelopen dertien jaar Victor van der Stoep voor de verschillende bedrijven van Jan Willem oplossingen gebouwd om data te controleren, inzichtelijk te maken en te presenteren. De ene keer ging het om verbruiks- en/of productiedata, dan om weerdata en vaak om prijsdata.
          Deze, vaak intensieve, samenwerking heeft ertoe geleid dat Jan Willem en Victor in 2021 hebben besloten een energiedataplatform te bouwen waar alles in samenkomt. Een platform dat verschillende soorten gebruikers de voor hen relevante informatie simpel en doeltreffende kan presenteren op een real- of near-time basis, inclusief historische ontwikkelingen én verwachtingen voor de komende periodes.
        </p>
        <h2 class="font-bold text-2xl py-5">Inzicht</h2>
        <p>
          Dé energiemarkt bestaat niet. Energiemarkten zijn lokaal verweven en afhankelijk van beschikbare infrastructuren. De energiehandel is echter mondiaal en korte en/of lange termijn energieprijzen worden beïnvloed door geopolitiek, opslag- en transportmogelijkheden én lokale of regionale weersomstandigheden.
          Het energiedataplatform van Profiteia haalt mondiaal data op om haar gebruikers niet alleen het inzicht te verschaffen dat zij nodig hebben de juiste besluiten te nemen maar ook het overzicht. Gebruikers van Profiteia hebben nog maar één databron nodig om al hun informatie te vergaren. En is de benodigde data er nog niet? Dan overleggen we hoe deze alsnog toegevoegd kan worden.
          Profiteia biedt haar gebruikers verschillende mogelijkheden om het juiste inzicht te verkrijgen, maar allemaal gebaseerd op het software-as-a-service-principe. Gebruikers kunnen hun eigen dashboards samenstellen op basis van de verschillende, door Profiteia gegenereerde, analyses. Gebruikers kunnen ook toegang krijgen tot ons Big-Data-platform waarin ze hun eigen analyses kunnen uitvoeren. Voor het genereren van forecasts kunnen gebruikers hun eigen logaritmes ontwikkelen en toevoegen maar ook die van Profiteia gebruiken.
        </p>
      </div>
      <div v-if="$i18n.locale === 'en'" class="bg-white p-5">
        <span class="font-bold block mb-2">Since 2003</span>
        <p>
          The idea for an energy data platform such as Profiteia arose in 2003 when the energy market had just been liberalised. Jan Willem Zwang had been in the energy market for two years at the time and soon realized that high-quality data is indispensable for participation in the energy market. The timely availability of good quality and relevant data is essential when developing a vision, drawing up strategy and implementing energy policy for the deployment of energy production, energy consumption and energy storage.
          Over the past thirteen years, Victor van der Stoep has built solutions for the various companies founded and owned by Jan Willem to collect, consolidate and visualize data. Sometimes it was about consumption and/or production data, then about weather data and often about price data.
          The collaboration between Jan Willem and Victor finally led to the decision in 2021 to build an energy data platform in which everything comes together. A platform that can present relevant information in a simple and effective way on a real- or near-time basis, including historical developments and expectations for the coming periods. And this for different types of users.
        </p>
        <span class="font-bold block my-2">Insight</span>
        <p>
          The energy market does not exist. Energy markets are locally intertwined and dependent on available infrastructures. However, energy trade is global and short and/or long term energy prices are influenced by geopolitics, storage and transport options and local or regional weather conditions.
          Profiteia's energy data platform collects data worldwide to provide its users the insight and the overview they need to make the right decisions. Users only need Profiteia as a single data source to collect all their information. In case the data required is not yet available, the Profiteia team investigates the possibilities of obtaining the data after all.
          Profiteia offers its users various options to obtain the right insights, in a software-as-a-service package. Users can compose their own dashboards based on the various analyzes generated by Profiteia. Users can also access our Big-Data platform where they can perform their own analyses. To generate forecasts, users can develop and add their own logarithms, but also use those of Profiteia.
        </p>
      </div>
  </form-screen-section>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Level from '@/components/Level'
import MainSection from '@/components/MainSection'
import CardComponent from '@/components/CardComponent'
import FormScreenSection from '@/components/FormScreenSection'
import TextLink from '@/components/TextLink'
import Repository from '@/repositories/RepositoryFactory'

// Composable functions
const { t } = useI18n()
// const store = useStore()

// Reactive references
const titleStack = [t('menu_about_us')]
const data = ref(null)
const GraphQLRepository = Repository.get('graphql')

// Lifecycle hooks
onMounted(() => {
  // Fetch data using GraphQLRepository if needed
  // GraphQLRepository.getBlogs({
  //   query: `
  //     {
  //       blogs {
  //         data {
  //           attributes {
  //             name
  //             description
  //           }
  //         }
  //       }
  //     }
  //   `
  // }).then((result) => {
  //   data.value = result.data.data.blogs
  // })
})
</script>
